// validation helper functions
const validationHelperFunctions = {
  errors: {},

  isBoolean(value, path) {
    if (typeof value !== 'boolean') {
      this.errors[path] = `Expected boolean at ${path}`;
      return false;
    }
    return true;
  },

  isArray(value, path) {
    if (!Array.isArray(value)) {
      this.errors[path] = `Expected array at ${path}`;
      return false;
    }
    return true;
  },

  isNumber(value, path) {
    if (!Number.isInteger(value)) {
      this.errors[path] = `Expected integer at ${path}`;
      return false;
    }
    return true;
  },

  isDate(value, path) {
    if (!!isNaN(Date.parse(value)) && new Date(value).toISOString() !== value) {
      this.errors[path] = `Expected ISO date string at ${path}`;
      return false;
    }
    return true;
  },

  isNonEmptyString(value, path) {
    if (typeof value !== 'string' || value.trim() === '') {
      this.errors[path] = `Expected a non-empty string at ${path}`;
      return false;
    }
    return true;
  },

  validateArrayObjects(arr, schema, path) {
    if (!Array.isArray(arr)) {
      this.errors[path] = `Expected an array at ${path}`;
      return false;
    }
    return arr.every((item, index) => this.validateObject(item, schema, `${path}[${index}]`));
  },

  validateObject(obj, schema, path = '') {
    return Object.keys(schema).every((key) => {
      const value = obj[key];
      const check = schema[key];

      if (Array.isArray(check)) {
        return this.validateArrayObjects(value, check[0], `${path ? `${path}.` : ''}${key}`);
      }

      if (typeof check === 'object') {
        return this.validateObject(value, check, `${path ? `${path}.` : ''}${key}`);
      }

      if (typeof check === 'function') {
        return check(value, `${path ? `${path}.` : ''}${key}`);
      }

      this.errors[path ? `${path}.${key}` : key] = `Invalid value for ${
        path ? `${path}.${key}` : key
      }`;
      return false;
    });
  }
};

// info api response schema
const infoDataSchema = {
  subscription: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  urlStatus: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  domain: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  apiKey: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  url_path: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  requested_domain: (val, path) =>
    val === null || val === '' || validationHelperFunctions.isNonEmptyString(val, path),
  requested_subdomain: (val, path) =>
    val === null || val === '' || validationHelperFunctions.isNonEmptyString(val, path),
  requested_full_english_url: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  full_english_url: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  default_lang_code: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  languages: (val, path) =>
    validationHelperFunctions.validateArrayObjects(
      val,
      {
        code: validationHelperFunctions.isNonEmptyString,
        name: validationHelperFunctions.isNonEmptyString
      },
      path
    ),
  subdomains: (val, path) =>
    validationHelperFunctions.validateArrayObjects(
      val,
      {
        subdomain: validationHelperFunctions.isNonEmptyString,
        code: validationHelperFunctions.isNonEmptyString,
        name: validationHelperFunctions.isNonEmptyString
      },
      path
    ),
  enableSubdomainRedirect: (val, path) => validationHelperFunctions.isBoolean(val, path),
  enableLanguageDropdownUI: (val, path) => validationHelperFunctions.isBoolean(val, path),
  ui_position: (val, path) =>
    (typeof val === 'object' &&
      ['bottom', 'right', 'left'].every(
        (key) =>
          val[key] === null ||
          val[key] === '' ||
          validationHelperFunctions.isNonEmptyString(val[key], `${path}.${key}`)
      )) ||
    (errors[path] = `Expected object with optional non-empty strings at ${path}`),
  is_cached: (val, path) => validationHelperFunctions.isBoolean(val, path),
  cached_expire_time: (val, path) => true,
  token: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  token_type: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  expires_in: (val, path) => validationHelperFunctions.isNumber(val, path),
  blocked_url: (val, path) => validationHelperFunctions.isBoolean(val, path),
  linked_domains: (val, path) =>
    validationHelperFunctions.validateArrayObjects(
      val,
      {
        subdomain: validationHelperFunctions.isNonEmptyString,
        domain: validationHelperFunctions.isNonEmptyString,
        code: validationHelperFunctions.isNonEmptyString
      },
      path
    ),
  robots_txt: (val, path) =>
    val === null || val === '' || validationHelperFunctions.isNonEmptyString(val, path),
  enable_static_content: (val, path) => validationHelperFunctions.isBoolean(val, path),
  enable_object_mapping: (val, path) => validationHelperFunctions.isBoolean(val, path),
  enable_exclude_block: (val, path) => validationHelperFunctions.isBoolean(val, path),
  exclude_blocks: (val, path) => validationHelperFunctions.isArray(val, path),
  enable_include_block: (val, path) => validationHelperFunctions.isBoolean(val, path),
  include_blocks: (val, path) => validationHelperFunctions.isArray(val, path),
  iframe_ids: (val, path) => validationHelperFunctions.isArray(val, path),
  source_lang_code: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  url_date: (val, path) => validationHelperFunctions.isDate(val, path),
  is_demo: (val, path) => validationHelperFunctions.isBoolean(val, path),
  bypass_cookie: (val, path) =>
    val === null || val === '' || validationHelperFunctions.isNonEmptyString(val, path),
  is_query_hash_cached: (val, path) => validationHelperFunctions.isBoolean(val, path),
  enable_translate_manual_approved_segments: (val, path) =>
    validationHelperFunctions.isNumber(val, path),
  enable_robots_index: (val, path) => validationHelperFunctions.isBoolean(val, path),
  enable_subdomain_folder_structure: (val, path) => validationHelperFunctions.isBoolean(val, path),
  domain_setup_approach: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  urlStatusArray: (val, path) =>
    validationHelperFunctions.validateArrayObjects(
      val,
      {
        code: validationHelperFunctions.isNonEmptyString,
        status: validationHelperFunctions.isNonEmptyString
      },
      path
    ),
  isProxy: (val, path) => validationHelperFunctions.isBoolean(val, path),
  dropdown_color: (val, path) =>
    val === null || validationHelperFunctions.isNonEmptyString(val, path),
  enable_page_views: (val, path) => validationHelperFunctions.isBoolean(val, path)
};

// validate info res object
const validateInfoRes = (infoResObj) => {
  const isValid = validationHelperFunctions.validateObject(infoResObj, infoDataSchema);

  if (!isValid) {
    console.log(' -> Info response validation error: ', validationHelperFunctions.errors);
    throw new Error(`Info response validation error: ${validationHelperFunctions.errors}`);
  }
  return;
};

// return exact copy of status api response data
const extractStatusApiResData = (infoRes) => {
  const statusRes = getStatusResObject.call(infoRes);
  return statusRes;
};

// create status object
const getStatusResObject = function () {
  return {
    subscription: this.subscription,
    urlStatus: this.urlStatus,
    enableSubdomainRedirect: this.enableSubdomainRedirect,
    enableLanguageDropdownUI: this.enableLanguageDropdownUI,
    enable_object_mapping: this.enable_object_mapping,
    languages: this.languages,
    subdomains: this.subdomains,
    enable_exclude_block: this.enable_exclude_block,
    exclude_blocks: this.exclude_blocks,
    enable_include_block: this.enable_include_block,
    include_blocks: this.include_blocks,
    iframe_ids: this.iframe_ids,
    ui_position: this.ui_position,
    linked_domains: this.linked_domains,
    urlStatusArray: this.urlStatusArray,
    source_lang_code: this.source_lang_code,
    url_date: this.url_date,
    is_demo: this.is_demo,
    enable_translate_manual_approved_segments: this.enable_translate_manual_approved_segments,
    enable_robots_index: this.enable_robots_index,
    dropdown_color: this.dropdown_color,
    enable_page_views: this.enable_page_views
  };
};

module.exports = {
  validationHelperFunctions,
  validateInfoRes,
  extractStatusApiResData
};

const { getClientConfig } = require('../helpers/utils');

const clientConfig = getClientConfig('wep');

/**
 * Perform initialization tasks for this feature
 *
 * @param {object} config Configuration passed by initialization code
 * @param {object} urlObj Document URL object
 */
exports.initialize = async (config, urlObj) => {
  if (urlObj.hostname.includes(clientConfig.hostname[0])) {
    clientConfig.enabled = true;
  }
};

/**
 * Is used to check if the client code is enabled or not.
 *
 * @returns boolean
 */
exports.isEnabled = () => {
  return !!clientConfig.enabled;
};

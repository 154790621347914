const global_variables = require('../../config');
const { isProxy } = require('../helpers/mode');
const { escapeForwardSlashes } = require('./app-helpers');

function checkIfStringIsHindi(string) {
  const regex = /[\u0900-\u097F]/;
  return regex.test(string);
}

function checkIfStringIsPunjabi(string) {
  const regex = /[\u0A00-\u0A7F]/;
  return regex.test(string);
}

function checkIfStringIsTamil(string) {
  const regex = /[\u0B80-\u0BFF]/;
  return regex.test(string);
}

function checkIfStringIsGujrati(string) {
  const regex = /[\u0A80-\u0AFF]/;
  return regex.test(string);
}

function checkIfStringIsKannad(string) {
  const regex = /[\u0C80-\u0CFF]/;
  return regex.test(string);
}

function checkIfStringIsBengali(string) {
  const regex = /[\u0980-\u09FF]/;
  return regex.test(string);
}

function checkIfStringIsTelugu(string) {
  const regex = /[\u0C00-\u0C7F]/;
  return regex.test(string);
}

function checkIfStringIsMalyalam(string) {
  const regex = /[\u0D02-\u0D4D]/;
  return regex.test(string);
}

function checkIfStringIsAssamese(string) {
  const bengaliRegex = /[\u0980-\u09FF]/;
  //const tirhutaRegex = /[\u011480-\u0114DF]/;
  //return bengaliRegex.test(string) || tirhutaRegex.test(string);
  return bengaliRegex.test(string);
}

function checkIfStringIsOdia(string) {
  const regex = /[\u0B00-\u0B7F]/;
  return regex.test(string);
}

function checkIfStringIsArabic(string) {
  const regex = /[\u0600-\u06FF]/;
  return regex.test(string);
}

function checkIfStringIsJapanese(string) {
  const kanjiRegex = /[\u4E00-\u9FBF]/;
  const hiraganaRegex = /[\u3040-\u309F]/;
  const katakanaRegex = /[\u30A0-\u30FF]/;

  return kanjiRegex.test(string) || hiraganaRegex.test(string) || katakanaRegex.test(string);
}

function checkIfStringIsChinese(string) {
  const regex = /[\u4E00-\u9FFF]/;
  return regex.test(string);
}

function checkIfStringIsIndonesian(string) {
  const regex = /[\u1B00-\u1B7F]/;
  return regex.test(string);
}

function checkIfStringIsUrdu(string) {
  const regex1 = /[\u0600-\u06FF]/;
  const regex2 = /[\u0750-\u077F]/;
  const regex3 = /[\uFB50-\uFDFF]/;
  const regex4 = /[\uFE70-\uFEFF]/;

  return regex1.test(string) || regex2.test(string) || regex3.test(string) || regex4.test(string);
}

function checkIfStringIsEnglish(string) {
  const regex = /[a-z]/i;
  return regex.test(string);
}

function checkIfStringIsSpanish(string) {
  const regex = /[áéíóúüñÁÉÍÓÚÜÑ]/;
  return regex.test(string);
}

function checkIfStringIsFrench(string) {
  const regex = /[àâäéèêëîïôöùûüÿçÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ]/;
  return regex.test(string);
}

function checkIfStringIsLocal(string, hinglishResult = false) {
  if (global_variables.selected_lang_code === global_variables.source_lang_code) {
    return false;
  }

  switch (global_variables.selected_lang_code) {
    case 'hi': {
      return global_variables.source_lang_code === 'hi' ? false : checkIfStringIsHindi(string);
    }
    case 'en': {
      return global_variables.source_lang_code === 'en' ? false : checkIfStringIsEnglish(string);
    }
    case 'pa': {
      return global_variables.source_lang_code === 'pa' ? false : checkIfStringIsPunjabi(string);
    }
    case 'ta': {
      return checkIfStringIsTamil(string);
    }
    case 'gu': {
      return checkIfStringIsGujrati(string);
    }
    case 'kn': {
      return checkIfStringIsKannad(string);
    }
    case 'bn': {
      return checkIfStringIsBengali(string);
    }
    case 'mr': {
      return checkIfStringIsHindi(string);
    }
    case 'te': {
      return checkIfStringIsTelugu(string);
    }
    case 'ml': {
      return checkIfStringIsMalyalam(string);
    }
    case 'as': {
      return checkIfStringIsAssamese(string);
    }
    case 'or': {
      return checkIfStringIsOdia(string);
    }
    case 'ar': {
      return checkIfStringIsArabic(string);
    }
    case 'ja': {
      return global_variables.source_lang_code === 'ja' ? false : checkIfStringIsJapanese(string);
    }
    case 'zh':
    case 'zh-CN':
    case 'zh-TW': {
      return checkIfStringIsChinese(string);
    }
    case 'id': {
      return checkIfStringIsIndonesian(string);
    }
    case 'ur': {
      return checkIfStringIsUrdu(string);
    }
    case 'es': {
      return checkIfStringIsSpanish(string);
    }
    case 'fr': {
      return checkIfStringIsFrench(string);
    }
    case 'hi-EN':
    case 'tl':
    case 'ko':
    case 'ru':
    case 'th':
    case 'pl':
    case 'no':
    case 'hu':
    case 'sv':
    case 'de':
    //case 'es':
    //case 'fr':
    case 'it':
    case 'nl':
    case 'pt':
    case 'vi':
    case 'id': {
      return hinglishResult;
    }
    default: {
      return false;
    }
  }
}

function replaceLocalizedUrl(url, config) {
  const domainWithoutProtocol = config.requested_domain.replace(/^https?:\/\//, '');
  const subDomainWithoutProtocol = config.requested_sub_domain.replace(/^https?:\/\//, '');

  const secureDomain = `https://${domainWithoutProtocol}`;
  const secureSubDomain = `https://${subDomainWithoutProtocol}`;

  const unsecureDomain = `http://${domainWithoutProtocol}`;
  const unsecureSubDomain = `http://${subDomainWithoutProtocol}`;

  const escapedSecureDomain = escapeForwardSlashes(secureDomain);
  const escapedSecureSubDomain = escapeForwardSlashes(secureSubDomain);

  const escapedUnsecureDomain = escapeForwardSlashes(unsecureDomain);
  const escapedUnsecureSubDomain = escapeForwardSlashes(unsecureSubDomain);

  url = url.replaceAll(secureDomain, secureSubDomain);
  url = url.replaceAll(unsecureDomain, unsecureSubDomain);

  url = url.replaceAll(encodeURIComponent(secureDomain), encodeURIComponent(secureSubDomain));
  url = url.replaceAll(encodeURIComponent(unsecureDomain), encodeURIComponent(unsecureSubDomain));

  if (config.linked_domains) {
    for (let linkedDomain of config.linked_domains) {
      url = url.replaceAll(linkedDomain.domain, linkedDomain.subdomain);
    }
  }

  url = url.replaceAll(escapedSecureDomain, escapedSecureSubDomain);

  url = url.replaceAll(escapedUnsecureDomain, escapedUnsecureSubDomain);

  return url;
}

// for live js mode default lang code will be set in login api
function setDefaultLanguage(config) {
  if (isProxy()) {
    if (!config.default_lang_code) {
      config.default_lang_code = 'en';
    }
    global_variables.default_lang_code = config.default_lang_code;
  }
}

/**
 * checks if the sentence is merged sentence
 * @param {string} sentence
 * @returns boolean
 */
function checkForMergeTag(sentence) {
  if (sentence.includes(global_variables.sentence_merge_separator_send)) {
    return true;
  }

  return !checkIfStringIsLocal(sentence, false);
}

// Helper to escape regex special characters in the domain
function escapeRegExpDomain(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

module.exports = {
  checkIfStringIsHindi,
  checkIfStringIsGujrati,
  checkIfStringIsEnglish,
  checkIfStringIsLocal,
  setDefaultLanguage,
  replaceLocalizedUrl,
  checkForMergeTag,
  escapeRegExpDomain
};

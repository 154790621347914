const global_variables = require('../../config');
const { sourceLanguageCleanString } = require('../helpers/utils');

function handleSpanTag(str) {
  return str.replace(/<span([^>]*?)>(.*?)<\/span>/g, (match, attributes, content) => {
    const escapedContent = content.replace(/\|\|/g, '\\|\\|');
    return `<span${attributes}>${escapedContent}</span>`;
  });
}

// common function to replace variables translation
function replaceVariablesTranslation(text, sentences, variables, isServer = false) {
  // const reconstructionTemplate = new RegExp(handleSpanTag(variables.reconstructionTemplate));
  const reconstructionTemplate = new RegExp(variables.reconstructionTemplate);
  if (reconstructionTemplate.test(text)) {
    const matches = text.match(reconstructionTemplate);
    let replacedOriginalText = !isServer
      ? sourceLanguageCleanString(sentences.sentence)
      : sentences.sentence;
    let replacedTranslatedText = sentences.translation;
    if (matches) {
      for (let i = 0; i < variables.names.length; i++) {
        replacedOriginalText = replacedOriginalText.replace(
          `{%${variables.names[i].name}%}`,
          matches[i + 1]
        );
        replacedTranslatedText = replacedTranslatedText.replace(
          `{%${variables.names[i].name}%}`,
          matches[i + 1]
        );
      }

      if (isServer) return { replacedOriginalText, replacedTranslatedText };

      translatedText = replacedTranslatedText;
      const origText = sourceLanguageCleanString(replacedOriginalText, false);
      const trimmedText = sourceLanguageCleanString(replacedOriginalText);
      const translatedTextWithSpaces = origText.replace(trimmedText, translatedText);
      return { translatedTextWithSpaces, origText };
    }
  }
  return false;
}

module.exports = {
  replaceVariablesTranslation
};

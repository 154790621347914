/* eslint-disable prettier/prettier */
const axios = require('axios');
const global_variables = require('../../config');
const features = require('../features');
const { isLiveJs, isProxy } = require('../helpers/mode');
const {
  FormatTranslations,
  access_token,
  isTextTranslatedText,
  getSentenceCombinedOriginalText,
  replaceEnglishDomainWithLocalDomain,
  replaceNbsps,
  getLocalObjectUrl,
  isUrlLangStatusActive,
  configureStatusApiResObject,
  validateStatusRes
} = require('../helpers/app-helpers.js');
const { devngari_logo_svg_content, up_arrow_svg_content } = require('../helpers/svgcontent');
const { openDropdown, closeDropdown } = require('./dropdown-service');
const {
  cleanString,
  sourceLanguageCleanString,
  mergedSentencesXpath,
  getXPathForElement,
  isObjEmpty,
  checkIfSegmentIsValid,
  isIndexDbSupportedByBrowser
} = require('../helpers/utils');
const { checkIfStringIsLocal, checkForMergeTag } = require('../helpers/language-helpers');
const { setDropdownPosition } = require('./dropdown-service');
// const {
//   setTranslatedTextToSentenceObj,
// } = require("./translation.service.js");

const {
  close_button_svg_content,
  edit_button_svg_content,
  save_button_svg_content,
  seo_button_svg_content
} = require('../helpers/svgcontent.js');

const { node_blacklist } = require('./blacklisting-service');
const { contentEdit } = require('./incontext-editor-service');
const translationsDb = require('../helpers/translationsDb-helper.js');
const { replaceVariablesTranslation } = require('../helpers/variable-helper.js');
const wep = require('../../sdk/clients/wep.js');
const idfcfa = require('../../sdk/clients/idfcfa.js');

function setTranslatedContentToSentenceNodes(sentenceNodes) {
  Object.values(sentenceNodes).forEach(setTranslatedContentToSentenceParent);
}

function setTranslatedTextToSentenceObj(sentenceObj) {
  if (
    sourceLanguageCleanString(sentenceObj.original_text) ===
    sourceLanguageCleanString(sentenceObj.translated_text)
  ) {
    return;
  }
  let translatedTextsArr = [];
  if (sentenceObj.translated_text) {
    translatedTextsArr = sentenceObj.translated_text.split(
      global_variables.sentence_merge_separator_receive
    );
  }

  const children = sentenceObj.children.map((child, index) => {
    child.translated_text = translatedTextsArr[index] ? translatedTextsArr[index] : '';
    child.xpath = sentenceObj.xpathChild ? sentenceObj.xpathChild[index] : '';
    return child;
  });
  sentenceObj.children = children;
  return sentenceObj;
}

function setTranslatedContentToSentenceParent(sentenceObj) {
  const sentenceParent = setTranslatedTextToSentenceObj(sentenceObj);

  if (
    sentenceParent &&
    sentenceParent.children &&
    Array.isArray(sentenceParent.children) &&
    sentenceParent.children.length > 0
  ) {
    sentenceParent.children.forEach((child) => {
      if (child.original_text && !isTextTranslatedText(child.original_text)) {
        const origText = sourceLanguageCleanString(child.original_text, false);
        const trimmedText = sourceLanguageCleanString(child.original_text);
        const translatedTextWithSpaces = origText.replace(trimmedText, child.translated_text);
        setTranslatedTextToElement(
          child.node,
          translatedTextWithSpaces,
          child.original_text,
          false,
          '',
          child.xpath,
          document
        );
      }
    });
  }
}

function renderTranslateUI() {
  if (
    global_variables.should_render_ui &&
    global_variables.should_render_ui.toLowerCase() === 'custom'
  ) {
    return true;
  }

  if (global_variables.renderUICounter < 1) {
    return true;
  }

  const outerMostDiv = document.createElement('div');
  outerMostDiv.id = 'devnagri_outermostdiv';
  outerMostDiv.setAttribute('class', 'devnagri_outermostdivclass');
  let position = '  z-index: 2147483647;position:fixed;';

  if (global_variables.status_api_global_data.ui_position) {
    const uiPosition = global_variables.status_api_global_data.ui_position;
    if (uiPosition.bottom) {
      position += `bottom:${uiPosition.bottom};`;
    }
    if (uiPosition.left) {
      position += `left:${uiPosition.left};`;
    }
    if (uiPosition.right) {
      position += `right:${uiPosition.right};`;
    }
  } else {
    if (global_variables.ui_bottom) {
      position += `bottom:${global_variables.ui_bottom};`;
    }
    if (global_variables.ui_left) {
      position += `left:${global_variables.ui_left};`;
    }
    if (global_variables.ui_right) {
      position += `right:${global_variables.ui_right};`;
    }
  }
  outerMostDiv.style.cssText = position;

  global_variables.initialdiv = document.createElement('div');
  global_variables.initialdiv.id = 'devnagri_outermostdiv';
  global_variables.initialdiv.classList.add('devnagri_open_button');

  // if (global_variables.web_owner) {
  //   WebOwnerEnableButton();
  // }

  const div4 = document.createElement('div');
  div4.setAttribute('style', 'float:left');
  div4.classList.add('check-background-color');
  const logo = document.createElement('div');
  logo.classList.add('devnagri_logo', 'Devnagri-tooltip-toggle-pencil');
  logo.id = 'devnagri_optionElementt';
  logo.setAttribute('style', 'background-color:white');
  logo.setAttribute('data-tooltip', 'Powered By Devnagri');
  logo.innerHTML = devngari_logo_svg_content;

  div4.appendChild(logo);
  global_variables.initialdiv.appendChild(div4);
  const div5 = document.createElement('div');

  div5.setAttribute('style', 'float:left');
  global_variables.defaultLanguage = document.createElement('h3');
  // global_variables.defaultLanguage.setAttribute('style', 'background-color:white');
  global_variables.defaultLanguage.classList.add('devnagri_written_lang');
  global_variables.defaultLanguage.id = 'devnagri_optionElementt';
  global_variables.defaultLanguage.innerText = '';
  global_variables.defaultLanguage.onclick = () => openDropdown();
  div5.appendChild(global_variables.defaultLanguage);
  global_variables.initialdiv.appendChild(div5);

  const div6 = document.createElement('div');
  div6.classList.add('check-background-color');
  div6.setAttribute('style', 'float:left');
  const upArrow = document.createElement('div');
  upArrow.classList.add('devnagri_upArrow');
  upArrow.id = 'devnagri_optionElementt';
  // upArrow.setAttribute('style', 'background-color:white');
  upArrow.onclick = () => openDropdown();
  upArrow.innerHTML = up_arrow_svg_content;
  div6.appendChild(upArrow);
  global_variables.initialdiv.appendChild(div6);

  const body = document.getElementsByTagName('body')[0];

  if (global_variables.status_api_global_data) {
    body.appendChild(global_variables.initialdiv);
    if (document.querySelector('.devnagri_owner_button')) {
      document.querySelector('.devnagri_owner_button').style.display = 'none';
    }
    if (document.querySelector('.devnagri_vl')) {
      document.querySelector('.devnagri_vl').style.display = 'none';
    }
    if (global_variables.default_lang_code == 'en') {
      if (document.querySelector('.devnagri_owner_button')) {
        document.querySelector('.devnagri_owner_button').style.display = 'none';
        document.querySelector('.devnagri_vl').style.display = 'none';
      }
    } else {
      if (document.querySelector('.devnagri_owner_button')) {
        document.querySelector('.devnagri_owner_button').style.display = 'block';
      }
      if (document.querySelector('.devnagri_vl')) {
        document.querySelector('.devnagri_vl').style.display = 'block';
      }
    }
  }
  outerMostDiv.appendChild(global_variables.initialdiv);
  document.body.insertBefore(global_variables.initialdiv, document.body[0]);
  const div = document.createElement('div');
  div.id = 'devnagri_dropdown';
  div.className = 'devnagri_container';
  div.setAttribute('devnagri_option', 'devnagri_optionElement');
  const ul = document.createElement('ul');
  ul.setAttribute('type', 'none');
  div.appendChild(ul);

  const enableSubdomainRedirect =
    global_variables.status_api_global_data.enableSubdomainRedirect || isProxy();

  if (enableSubdomainRedirect && !global_variables.web_owner) {
    for (let i = 0; i < global_variables.status_api_global_data.subdomains.length; i++) {
      const li = document.createElement('li');
      const anchorTag = document.createElement('a');
      anchorTag.setAttribute('id', 'devnagri_subdomain');

      const currentSubdomain = global_variables.status_api_global_data.subdomains.find(
        (subdomain) => subdomain.code === global_variables.selected_lang_code
      );
      const isSubfolder =
        currentSubdomain && currentSubdomain.subdomain && currentSubdomain.subdomain.includes('/');

      let path = location.pathname;

      if (isSubfolder) {
        const pathArr = path.split('/');
        pathArr.shift();
        pathArr.shift();
        path = `/${pathArr.join('/')}`;

        anchorTag.setAttribute(
          'href',
          ` ${location.protocol}//${global_variables.status_api_global_data.subdomains[i].subdomain}${path}${location.search}`
        );
      } else {
        anchorTag.setAttribute(
          'href',
          ` ${location.protocol}//${global_variables.status_api_global_data.subdomains[i].subdomain}${location.pathname}${location.search}`
        );
      }
      anchorTag.innerHTML = global_variables.status_api_global_data.subdomains[i].name;
      li.appendChild(anchorTag);
      li.setAttribute('id', 'devnagri_li');
      li.setAttribute(
        'devnagri_lang_code_attribute',
        global_variables.status_api_global_data.subdomains[i].code
      );
      li.setAttribute('class', 'devnagri_optionElement');
      ul.appendChild(li);
    }
  } else {
    for (let i = 0; i < global_variables.lang.length; i++) {
      const li = document.createElement('li');
      const splitStr = global_variables.lang[i].name.split('-');
      li.setAttribute('id', 'devnagri_li');
      li.innerHTML = splitStr[splitStr.length - 1].trim();
      li.setAttribute('devnagri_lang_code_attribute', global_variables.lang[i].code);
      li.setAttribute('class', 'devnagri_optionElement');
      li.onclick = () => {
        onLangSelection(global_variables.lang[i].code);
      };
      ul.appendChild(li);
    }
  }

  outerMostDiv.appendChild(div);
  outerMostDiv.appendChild(global_variables.initialdiv);

  document.body.insertBefore(outerMostDiv, document.body[1]);

  // const selected_lang = localStorage.getItem("devnagri_selected_lang_code")
  //   ? localStorage.getItem("devnagri_selected_lang_code")
  //   : global_variables.default_lang_code;

  activeLanguage(global_variables.selected_lang_code);
  --global_variables.renderUICounter;
}

function setTranslatedTextToSentenceMapChildren(sentenceMap) {
  const obj = {};
  Object.entries(sentenceMap).forEach(([key, value]) => {
    if (
      sourceLanguageCleanString(value.original_text) !==
      sourceLanguageCleanString(value.translated_text)
    ) {
      let translatedTextsArr = [];
      if (value.translated_text) {
        translatedTextsArr = value.translated_text.split(
          global_variables.sentence_merge_separator_receive
        );
      }

      const children = value.children.map((child, index) => {
        child.translated_text = translatedTextsArr[index];
        return child;
      });
      value.children = children;
    }
    obj[key] = value;
  });
  return obj;
}

/**
 * common function to update translations of sentence_nodes or spa_sentence_nodes
 * @param {*} sentence_nodes
 * @param {*} origSentenceDataMap
 * @param {*} sentenceNodesMapByOrigText
 */
function handleSentenceNodeTranslation(
  sentence_nodes,
  origSentenceDataMap,
  sentenceNodesMapByOrigText
) {
  Object.entries(sentence_nodes).forEach(([key, value]) => {
    const sentenceData = origSentenceDataMap[key];

    sentenceNodesMapByOrigText[value.original_text] = {
      parentId: key,
      translated_text: sentenceData ? sentenceData.translation : value.original_text,
      ...value
    };

    // update the sentence node with translation and xpathChild
    sentence_nodes[key].translated_text = sentenceData
      ? sentenceData.translation
      : value.original_text;
    sentence_nodes[key].xpathChild = sentenceData ? sentenceData.xpathChild : [];

    /**
     * -- variable translations start
     * handle variables translation for sentence_nodes/spa_sentence_nodes if enabled
     */
    if (
      global_variables.enable_variable_system &&
      value.original_text &&
      global_variables.sentenceVariables[key] &&
      global_variables.sentenceVariables[key].variables.length > 0
    ) {
      const variableData = global_variables.sentenceVariables[key].variables[0];
      if (variableData.reconstructionTemplate) {
        const replacedTranslation = replaceVariablesTranslation(
          value.original_text,
          global_variables.sentenceVariables[key],
          variableData
        );
        if (replacedTranslation) {
          if (sentence_nodes[key].translated_text !== 'undefined') {
            sentence_nodes[key].translated_text = replacedTranslation.translatedTextWithSpaces
              ? replacedTranslation.translatedTextWithSpaces
              : replacedTranslation.origText;
            sentence_nodes[key].original_text = replacedTranslation.origText;
          }
        }
      }
    }
    // -- variable translations end --
  });

  global_variables.sentence_nodes_by_orig_text = sentenceNodesMapByOrigText;
}

// this function is used for calling translate api and here we get specified language text i.e. hindi
async function GetTranslationsApi(skipTranslationsDb = false) {
  try {
    if (global_variables.source_lang_code === global_variables.selected_lang_code) {
      return {};
    }

    // if (
    //   global_variables.status_api_global_data &&
    //   global_variables.status_api_global_data.enable_object_mapping
    // ) {
    //   global_variables.enable_object_mapping = true;
    //   await getObjectUrlsApi();
    // }

    const documentUrlWithoutInternalLink = removeInternalLinkFromUrl(
      global_variables.document_url_full
    );

    const currentPageUrlObj = new URL(documentUrlWithoutInternalLink);
    currentPageUrlObj.searchParams.delete('language_code');
    currentPageUrlObj.searchParams.delete('mode');
    currentPageUrlObj.searchParams.delete('dota-token');

    const translation_api_base_url = `${global_variables.api_baseurl}/translations?language_code=${
      global_variables.selected_lang_code
    }&url=${encodeURIComponent(currentPageUrlObj)}`;

    const translation_api_url = translation_api_base_url;
    // +
    // "&language_code=" +
    // lang_code;
    // const translationData = await callApiRequest(async () => {
    //   const res = await fetch(translation_api_url, {
    //     method: 'GET',
    //     headers: {
    //       Authorization: `Bearer ${access_token()}`
    //     }
    //   });
    //   if (res.status === 401 || res.status === 403) {
    //     throw { response: res };
    //   }
    //   const response = await res.json();
    //   return response;
    // });

    // if (
    //   isProxy() &&
    //   !global_variables.apiCounter &&
    //   String(global_variables.prevURL) != String(currentPageUrlObj.href)
    // ) {

    /**
     * description: we need to call getTranslation api only once if 'enable_translate_manual_approved_segments' is enabled i.e 1
     * and if it is disabled i.e 0, call getTranslation api's as per 'missing translation' logic
     *
     * first if: make sure to call getTranslation api atleast once
     * global_variables.enable_translate_manual_approved_segments => we are checking if 'enable_translate_manual_approved_segments' is enabled
     * String(global_variables.prevURL) != String(currentPageUrlObj.href) => handles SPA url changes if url is changed call getTranslation api
     *
     * else if: make sure NOT call getTranslation api more than once
     * handles case when 'enable_translate_manual_approved_segments' is enabled simply return, not calling getTranslation api's
     *
     * else: make sure to call getTranslation api's as per 'missing translation' logic
     * handles case when 'enable_translate_manual_approved_segments' is disabled, calls getTranslation api's
     *
     */
    if (
      global_variables.enable_translate_manual_approved_segments &&
      // !global_variables.apiCounter &&
      String(global_variables.prevURL) != String(currentPageUrlObj.href)
    ) {
      global_variables.prevURL = global_variables.prevURL
        ? global_variables.prevURL
        : currentPageUrlObj.href;

      // check subscription status
      if (global_variables.subscription_status_active) {
        global_variables.translationData = await callApiRequest(async () => {
          if (!isObjEmpty(global_variables.translationData) && !idfcfa.isEnabled()) {
            return global_variables.translationData;
          }
          const res = await fetch(translation_api_url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${access_token()}`
            }
          });
          if (res.status === 401 || res.status === 403) {
            throw { response: res };
          }
          const response = await res.json();
          return response;
        });
        // ++global_variables.apiCounter;
      }
    } else if (global_variables.enable_translate_manual_approved_segments) {
      return;
    } else {
      // check subscription status
      if (global_variables.subscription_status_active) {
        global_variables.translationData = await callApiRequest(async () => {
          /**
           * retrieve translations data from 'translationsDb' if no data found continue calling translations api
           * if useTranslationsIndexDbStorage is false, 'translationsDb' is skipped
           */
          if (
            isIndexDbSupportedByBrowser() &&
            global_variables.enable_index_db &&
            !skipTranslationsDb
          ) {
            startTraversingNow();
            const dataFromIndexDb = await translationsDb.retrieveTranslationsDataFromIndexDb();
            if (dataFromIndexDb) {
              return dataFromIndexDb;
            }
          }
          const res = await fetch(translation_api_url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${access_token()}`
            }
          });
          if (res.status === 401 || res.status === 403) {
            throw { response: res };
          }
          const response = await res.json();
          return response;
        });
      }
    }

    // console.log('global_variables.translationData',global_variables.translationData.sentences);
    // const dataToWrite = JSON.stringify(global_variables.translationData, null, 2);

    // // Specify the file path and name
    // const filePath = 'XpathFromSDKApi.txt';

    // try {
    //   // Write the data to the file synchronously
    //   fs.writeFileSync(filePath, dataToWrite);
    //   console.log('Data written to file successfully.');
    // } catch (error) {
    //   console.error('Error writing data to file:', error);
    // }

    if (global_variables.translationData.message === 'success') {
      // -- sentence merging changes start
      if (global_variables.enable_sentence_merging) {
        const origSentenceDataMap = await FormatTranslations(
          global_variables.translationData.sentences,
          true
        );

        const sentenceNodesMapByOrigText = {};
        // update main global_variables.sentence_nodes translations
        handleSentenceNodeTranslation(
          global_variables.sentence_nodes,
          origSentenceDataMap,
          sentenceNodesMapByOrigText
        );

        // -- SPA translations start
        if (global_variables.enable_spa_support) {
          // to handle SPA sentence merging - assign only first load sentence_nodes
          if (global_variables.spa_first_load_flag) {
            global_variables.spa_sentence_nodes = global_variables.sentence_nodes;
            global_variables.spa_first_load_flag = false;
          } else {
            // update latest translations in spa_sentence_nodes
            handleSentenceNodeTranslation(
              global_variables.spa_sentence_nodes,
              origSentenceDataMap,
              sentenceNodesMapByOrigText
            );
          }
        }
        // -- SPA translations end --

        setTranslatedTextToSentenceMapChildren(global_variables.sentence_nodes_by_orig_text);

        setTranslatedTextToSentenceMapChildren(global_variables.sentence_nodes);

        setTranslatedContentToSentenceNodes(global_variables.sentence_nodes);

        /**
         * -- SPA translations start
         * handles SPA sentence merging (specifically the static content which is not captured by mutation observer)
         */
        if (global_variables.enable_spa_support) {
          setTranslatedContentToSentenceNodes(global_variables.spa_sentence_nodes);
        }
        // -- SPA translations end --
      }
      // -- sentence merging changes end --

      global_variables.translation_api_global_data = await FormatTranslations(
        global_variables.translationData.sentences
      );

      global_variables.translation_api_global_data_by_local_key = formatTranslationDataByLocalKey(
        global_variables.translation_api_global_data
      );

      await updateNodesWithTranslatedText(
        global_variables.translation_api_global_data,
        global_variables.translationData.sentences
      );

      if (
        isIndexDbSupportedByBrowser() &&
        global_variables.enable_index_db &&
        global_variables.subdomainObjectStore
      )
        translationsDb.storeTranslationsData(global_variables.translationData);
    }

    return global_variables.translationData;
  } catch (error) {
    console.log(error);
  }
}

function formatTranslationDataByLocalKey(translationDataByOrigKey) {
  const translationDataObjByLocalKey = {};
  for (const [key, value] of Object.entries(translationDataByOrigKey)) {
    translationDataObjByLocalKey[value.toLowerCase()] = key;
  }
  return translationDataObjByLocalKey;
}

// update api function calling
async function sentenceUpdateApi() {
  try {
    //await updatePageCache();

    let arrSentences = [];

    for (let [key, value] of Object.entries(global_variables.arr_nodes)) {
      if (
        !checkIfStringIsLocal(value.translatedText, false) &&
        !isTextTranslatedText(value.translatedText)
      ) {
        arrSentences.push({
          sentence: value.originalText,
          xpath: value.xpath,
          xpathParent: value.xpathParent,
          xpathChild: value.xpathChild,
          _idx: value._idx
        });
      }
    }

    const documentUrlWithoutInternalLink = removeInternalLinkFromUrl(
      global_variables.document_url_full
    );

    let mergedSentences = [];
    if (global_variables.enable_sentence_merging) {
      const result = mergedSentencesXpath(global_variables.sentence_nodes, document);
      mergedSentences = result;
    }

    // mergedSentences = mergedSentences.filter((sentence) => !checkIfStringIsLocal(sentence, false));
    mergedSentences = mergedSentences.filter((sentence) => {
      if (sentence.sentence) {
        return checkForMergeTag(sentence.sentence);
      }
      return !checkIfStringIsLocal(sentence, false);
    });

    let sentences = arrSentences.concat(mergedSentences);

    sentences = sentences
      .sort((a, b) => (a._idx > b._idx ? 1 : -1))
      .map((d) => ({
        sentence: d.sentence,
        xpath: d.xpath,
        xpathParent: d.xpathParent,
        xpathChild: d.xpathChild
      }));

    if (!compareArrays(global_variables.prevSentenceData, sentences) && sentences.length > 0) {
      global_variables.prevSentenceData = sentences;
      global_variables.sentenceData = await callApiRequest(async () => {
        const res = await fetch(global_variables.update_api_url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token()}`
          },
          body: JSON.stringify({
            url: documentUrlWithoutInternalLink,
            language_code: global_variables.default_lang_code,
            sentences,
            // this name is not appropriate
            added_from: 'sdk'
          })
        });
        if (res.status === 401 || res.status === 403) {
          throw { response: res };
        }
        const response = await res.json();
        return response;
      });
      return global_variables.sentenceData;
    }

    // if 'sentences' array is empty simply return, don't call post api
    return;
  } catch (error) {
    // throw error;
    console.log(error);
  }
}

// wrapper function for api calls to handle auth errors
async function callApiRequest(reqFunction) {
  try {
    return await reqFunction();
  } catch (error) {
    // handle auth error
    if (error && error.response && [401, 403, '401', '403'].includes(error.response.status)) {
      const authResponse = await authenticate(global_variables.api_key);
      if (authResponse) {
        return await reqFunction();
      }
    } else {
      throw error;
    }
  }
}

// authenticate user by login and subscription status apis
async function authenticate(apiKey) {
  try {
    const loginPromise = await loginApi(global_variables.login_api_url, apiKey);
    if (loginPromise) {
      const subscriptionPromise = await subscriptionStatusApi(global_variables.status_api_url);
      if (subscriptionPromise.data.subscription === 'ACTIVE') {
        return subscriptionPromise;
      }
    }
  } catch (err) {
    console.log(err);
  }
}

// this function is used for authentication
async function loginApi(url, apiKey) {
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        api_key: apiKey
      })
    });
    const data = await res.json();
    if (data.access_token) {
      global_variables._access_token = data.access_token;
      if (isLiveJs()) {
        if (global_variables.web_owner) {
          global_variables.default_lang_code =
            global_variables.url_object.searchParams.get('language_code');
        } else if (data.default_lang_code) {
          global_variables.default_lang_code = data.default_lang_code;
        }
      }
      return data;
    }
    return false;
  } catch (err) {
    // throw err;
    console.log(err);
  }
}

// this function is used for checking the status like active,inactive of authentication
async function subscriptionStatusApi(status_api_url) {
  try {
    const res = await fetch(status_api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token()}`
      },
      body: JSON.stringify({
        url: global_variables.document_url_full,
        language_code: global_variables.selected_lang_code
        //ip: global_variables.user_ip,
        //browser: navigator.userAgent,
        //timezone: new Date(),
        //referrer: global_variables.document_url,
        //browserLanguage: navigator.language,
        //sizeScreenWidth: screen.width,
        //sizeScreenHeight: screen.height,
        // location permission granted here for getting lat and long
        //latitude: global_variables.user_lat,
        //longitude: global_variables.user_long
      })
    });
    if (res.status === 401) {
      throw { response: res };
    }
    const response = await res.json();

    // validate status api response
    validateStatusRes(response.data);

    global_variables.status_api_global_data = response.data;

    // set status configuration
    configureStatusApiResObject(response.data);

    setDropdownPosition(response.data.ui_position);

    return response;
  } catch (err) {
    // throw err;
    console.log(err);
  }
}

async function pageViewsApi(page_view_api_url) {
  try {
    const res = await fetch(page_view_api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${global_variables._access_token}`
      },
      body: JSON.stringify({
        url: global_variables.document_url_full,
        language_code: global_variables.selected_lang_code,
        ip: global_variables.user_ip,
        browser: navigator.userAgent,
        timezone: new Date(),
        referrer: global_variables.document_url,
        browserLanguage: navigator.language,
        sizeScreenWidth: screen.width,
        sizeScreenHeight: screen.height,
        //location permission granted here for getting lat and long
        latitude: global_variables.user_lat,
        longitude: global_variables.user_long
      })
    });
    if (res.status === 401) {
      throw { response: res };
    }
    const response = await res.json();
    return response;
  } catch (err) {
    console.log(1, err);
    throw err;
  }
}

// this function is used for checking authentication which follows flow like first login,status,renderui and then translation
async function checkAuthenticationToken(expiryTime, apiKey) {
  try {
    // if there is expiry time
    if (expiryTime) {
      const date = new Date();
      const currentTime = date.getTime() + 1000 * 1 * 60 * 5; // here we added 5 minute offset, we wil get new auth token 5 minute before it expires
      // if there is expiry time which has been expired
      if (currentTime > expiryTime) {
        localStorage.removeItem('devnagri_token');
        localStorage.removeItem('devnagri_token_expiry_time');
        // just generating new token
        const loginPromise = await loginApi(global_variables.login_api_url, apiKey);
        if (loginPromise) {
          const subscriptionPromise = await subscriptionStatusApi(global_variables.status_api_url);
          if (global_variables.web_owner) {
            if (subscriptionPromise.data.subscription == 'ACTIVE') {
              global_variables.enable_object_mapping =
                subscriptionPromise.data.subscription.enable_object_mapping;
              renderTranslateUI();
              global_variables.subscription_status_active = true;
              await GetTranslationsApi();
            } else {
              global_variables.subscription_status_active = false;
            }
          } else if (subscriptionPromise.data.subscription == 'ACTIVE') {
            renderTranslateUI();
            if (subscriptionPromise.data.urlStatus == 'ACTIVE') {
              global_variables.enable_object_mapping =
                subscriptionPromise.data.subscription.enable_object_mapping;
              global_variables.subscription_status_active = true;
              await GetTranslationsApi();
            } else {
              global_variables.subscription_status_active = false;
            }
          } else {
            global_variables.subscription_status_active = false;
          }
        }
      }
      // if there is expiry time which has not been expired
      else {
        const subscriptionPromise = await subscriptionStatusApi(global_variables.status_api_url);

        if (global_variables.web_owner) {
          if (subscriptionPromise.data.subscription == 'ACTIVE') {
            global_variables.enable_object_mapping =
              subscriptionPromise.data.subscription.enable_object_mapping;
            renderTranslateUI();
            global_variables.subscription_status_active = true;

            await GetTranslationsApi();
          } else {
            global_variables.subscription_status_active = false;
          }
        } else if (subscriptionPromise.data.subscription == 'ACTIVE') {
          renderTranslateUI();
          if (subscriptionPromise.data.urlStatus == 'ACTIVE') {
            global_variables.enable_object_mapping =
              subscriptionPromise.data.subscription.enable_object_mapping;
            global_variables.subscription_status_active = true;

            await GetTranslationsApi();
          } else {
            global_variables.subscription_status_active = false;
          }
        } else {
          global_variables.subscription_status_active = false;
        }
      }
    } else {
      // if there is no expiry time then here expiry time will be generated
      if (localStorage.getItem('devnagri_token')) {
        localStorage.removeItem('devnagri_token');
      }
      if (localStorage.getItem('devnagri_token_expiry_time')) {
        localStorage.removeItem('devnagri_token_expiry_time');
      }

      // call login and status api if we don't have token and status data
      if (!access_token() || isObjEmpty(global_variables.status_api_global_data)) {
        const loginPromise = await loginApi(global_variables.login_api_url, apiKey);
        if (loginPromise) {
          const subscriptionPromise = await subscriptionStatusApi(global_variables.status_api_url);
          if (subscriptionPromise?.data.subscription == 'ACTIVE') {
            renderTranslateUI();
            if (subscriptionPromise.data.urlStatus == 'ACTIVE') {
              global_variables.subscription_status_active = true;
              await GetTranslationsApi();
            }
          } else if (
            // eslint-disable-next-line no-dupe-else-if
            subscriptionPromise?.data.subscription == 'ACTIVE' &&
            global_variables.web_owner
            // &&
            // subscriptionPromise.data.urlStatus != "ACTIVE"
          ) {
            global_variables.subscription_status_active = true;
            renderTranslateUI();
            await GetTranslationsApi();
          }
        }
      } else {
        // if we already have token and status api data, use it
        if (global_variables.status_api_global_data.subscription == 'ACTIVE') {
          renderTranslateUI();
          if (global_variables.status_api_global_data.urlStatus == 'ACTIVE') {
            global_variables.subscription_status_active = true;
            await GetTranslationsApi();
          }
        } else if (
          // eslint-disable-next-line no-dupe-else-if
          global_variables.status_api_global_data.subscription == 'ACTIVE' &&
          global_variables.web_owner
          // &&
          // subscriptionPromise.data.urlStatus != "ACTIVE"
        ) {
          global_variables.subscription_status_active = true;
          renderTranslateUI();
          await GetTranslationsApi();
        }
      }
    }
    // return true;
  } catch (err) {
    // throw err;
    console.log(err);
  }
}

async function getObjectUrlsApi() {
  const langCode = global_variables.selected_lang_code;

  const apiUrl = `${global_variables.api_baseurl}/object-mapping?language_code=${langCode}`;
  // const res = await fetch(apiUrl, {
  //   method: 'GET',
  //   headers: {
  //     Authorization: `Bearer ${access_token()}`
  //   }
  // });
  // if (res.status === 401 || res.status === 403) {
  //   callApiRequest();
  // }
  // const responseData = await res.json();

  if (!global_variables.objectMappingapiCounter) {
    global_variables.objectMappingData = await callApiRequest(async () => {
      const res = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${access_token()}`
        }
      });
      if (res.status === 401 || res.status === 403) {
        throw { response: res };
      }
      const response = await res.json();
      return response;
    });
    ++global_variables.objectMappingapiCounter;
  }

  let objectsArr = [];
  // if (responseData.data && Array.isArray(responseData.data)) {
  //   objectsArr = responseData.data;
  // }
  if (
    global_variables.objectMappingData.data &&
    Array.isArray(global_variables.objectMappingData.data)
  ) {
    objectsArr = global_variables.objectMappingData.data;
  }
  const objectsMap = {};
  const objectsMapLocal = {};
  objectsArr.forEach((object) => {
    objectsMap[object.english_url] = object;
    objectsMapLocal[object.local_url] = object;
  });
  global_variables.subdomain_object_urls_hash_map = objectsMap;

  if (
    global_variables.subdomain_object_urls_hash_map &&
    typeof global_variables.subdomain_object_urls_hash_map === 'object'
  ) {
    const objectsArr = Object.values(global_variables.subdomain_object_urls_hash_map);
    const localObjectUrlsHashMap = {};
    objectsArr.forEach((object) => {
      localObjectUrlsHashMap[object.local_url] = object;
    });
    global_variables.subdomain_object_urls_hash_map_local = localObjectUrlsHashMap;
  }
}

function removeInternalLinkFromUrl(url = '', keepQueryParams = false) {
  return url;
  const hashIndex = url.indexOf('#');
  const questionIndex = url.indexOf('?');
  const internalLinkExist = hashIndex > 0;
  const queryExist = questionIndex > 0;
  const queryIsAfterInternalLink = questionIndex > hashIndex;
  if (internalLinkExist) {
    if (keepQueryParams && queryExist && queryIsAfterInternalLink) {
      return url.slice(0, hashIndex) + url.slice(questionIndex, url.length);
    }

    return url.slice(0, hashIndex);
  }
  return url;
}

// ====================================
// Dom Helper
// ====================================

async function traverseAndSetTranslationOfWholePage() {
  startTraversingNow();
  await checkIfTraversingCompleted();
  global_variables.traverse_whole_dom = false;
}

async function updateGlobalData() {
  if (!global_variables.update_global_data_timeout) {
    global_variables.update_global_data_timeout = setTimeout(async () => {
      await startTraversingNow();
      await GetTranslationsApi();
      const res = await sentenceUpdateApi();
      // await delay(2000);
      startTraversingNow();
      await checkIfTraversingCompleted();
      if (res) {
        // call get translations api only if post translation api is called
        await GetTranslationsApi(true);
      }
      if (global_variables.traverse_whole_dom) {
        traverseAndSetTranslationOfWholePage();
      }
      global_variables.update_global_data_timeout = null;
    }, 1000);
  }
}

async function delay(ms) {
  return new Promise((res) => setTimeout(res, ms));
}

function isNodeASentence(node) {
  if (!global_variables.enable_sentence_merging) {
    return false;
  }
  let result = true;
  const parentName = node.nodeName.toUpperCase();
  if (global_variables.sentence_parents.includes(parentName)) {
    if (node.childNodes.length > 1) {
      for (const innerNode of node.childNodes) {
        const childName = innerNode.nodeName.toUpperCase();
        if (!global_variables.sentence_children.includes(childName)) {
          result = false;
        } else if (innerNode.childNodes.length > 1) {
          result = false;
        }
      }
    } else {
      result = false;
    }
  } else {
    result = false;
  }
  return result;
}

// this function is used for handling the nodes text with different-2 cases
function checkNodeType(
  node,
  isNodeASentenceChildren = false,
  sentenceParentId = '',
  subSentencePosition
) {
  if (node.parentElement && node.parentElement.nodeName == 'STYLE') {
    return;
  }

  switch (node.nodeName) {
    case '#text': {
      if (node.textContent.trim().length != 0) {
        try {
          const nodeParent = node.parentElement;
          if (
            nodeParent &&
            nodeParent.nodeName.toLowerCase() != 'devnagri_text' &&
            nodeParent.nodeName.toLowerCase() != 'title'
          ) {
            if (global_variables.web_owner) {
              const newElement = document.createElement('devnagri_text');
              newElement.setAttribute('english_text', node.textContent);
              newElement.textContent = node.textContent;
              node.replaceWith(newElement);
              addNodeToArray(
                node.textContent,
                newElement,
                null,
                isNodeASentenceChildren,
                sentenceParentId,
                subSentencePosition
              );
            } else {
              addNodeToArray(
                node.textContent,
                node,
                null,
                isNodeASentenceChildren,
                sentenceParentId,
                subSentencePosition
              );
            }
          } else {
            if (
              node.parentElement &&
              node.parentElement.nodeName == 'TITLE' &&
              node.textContent == document.title
            ) {
              addNodeToArray(global_variables.meta_prefix + node.textContent, node);
              break;
            }
            if (node.textContent.trim().length != 0) {
              addNodeToArray(node.textContent, node);
            }
          }
        } catch (error) {
          console.log('errorrrrrrrrr:', error);
          // const newElement = document.createElement('devnagri_text');
          // newElement.setAttribute('english_text', node.textContent);
          // newElement.textContent = node.textContent;
          // node.replaceWith(newElement);
          // addNodeToArray(node.textContent, newElement);
        }
      }
      break;
    }
    case 'META': {
      if (
        (node.name &&
          ['DESCRIPTION', 'TITLE', 'KEYWORDS', 'TWITTER:TITLE', 'TWITTER:DESCRIPTION'].includes(
            node.name.toUpperCase()
          )) ||
        (node.getAttribute('property') &&
          ['OG:TITLE', 'OG:DESCRIPTION'].includes(node.getAttribute('property').toUpperCase()))
      ) {
        if (node.name) {
          global_variables.metatag_key = `${node.nodeName}-${node.name}`;
          if (!global_variables.metatag_arr[`${global_variables.metatag_key}`]) {
            global_variables.metatag_arr[`${global_variables.metatag_key}`] = {
              tag: node.nodeName,
              name: node.name,
              content: node.content,
              engText: node.content,
              translatedText: node.content
            };
          }
        } else if (node.getAttribute('property')) {
          global_variables.metatag_key = `${node.nodeName}-${node.getAttribute('property')}`;
          if (!global_variables.metatag_arr[`${global_variables.metatag_key}`]) {
            global_variables.metatag_arr[`${global_variables.metatag_key}`] = {
              tag: node.nodeName,
              property: node.getAttribute('property'),
              content: node.content,
              engText: node.content,
              translatedText: node.content
            };
          }
        }
        addNodeToArray(global_variables.meta_prefix + node.content, node);
      }
      break;
    }

    case 'INPUT': {
      if (node.type === 'button') {
        if (node.value.trim().length != 0) {
          addNodeToArray(node.value, node);
        }
      } else {
        if (node.type === 'submit') {
          if (node.value.trim().length != 0) {
            addNodeToArray(node.value, node);
          }
        }

        if (node.placeholder.trim().length != 0) {
          addNodeToArray(node.placeholder, node, 'placeholder');
        }

        if (node.type != 'hidden' && node.title.trim().length != 0) {
          addNodeToArray(node.title, node, 'title');
        }
      }

      break;
    }
    case 'TEXTAREA': {
      if (node.placeholder.trim().length != 0) {
        addNodeToArray(node.placeholder, node, 'placeholder');
      }
      if (node.title.trim().length != 0) {
        addNodeToArray(node.title, node, 'title');
      }
      break;
    }
    case 'IMG': {
      if (global_variables.enable_object_mapping) {
        if (global_variables.subdomain_object_urls_hash_map_local[node.src]) {
          break;
        }
      }

      if (node.alt.trim().length != 0) {
        addNodeToArray(global_variables.meta_prefix + node.alt, node, 'alt');
      }

      if (node.title.trim().length != 0) {
        addNodeToArray(global_variables.meta_prefix + node.title, node, 'title');
      }
      break;
    }
    case 'A': {
      if (node.title.trim().length != 0) {
        addNodeToArray(global_variables.meta_prefix + node.title, node, 'title');
      }
      break;
    }
    case 'SCRIPT': {
      break;
    }
  }
  // if (node && node.parentElement && node.parentElement.title) {
  //   addNodeToArray(
  //     node.parentElement.title,
  //     node.parentElement,
  //     undefined,
  //     undefined,
  //     undefined,
  //     'title'
  //   );
  // }
}

// this function is used for traversing the HTML dom
async function traverseNodes(
  node,
  callback,
  isNodeASentenceChildren = false,
  sentenceParentId = '',
  subSentencePosition
) {
  const blacklistedTags = [
    'SCRIPT',
    'VIDEO',
    'IFRAME',
    'STYLE',
    'NOSCRIPT',
    'LINK',
    'SVG',
    'RECT',
    'PATH',
    'G',
    'CLIPPATH'
  ]; // handling the script tag

  /**
   * Loop through features on nodes for various triggers,
   * enhancement and updates.
   *
   * We can possibly remove await from this for loop to run
   * all features asynchronously.
   */
  for await (const feature of features) {
    // Check if feature is enabled and it has a function for traversing nodes.
    if (
      feature.isEnabled &&
      'traverseNodes' in feature &&
      typeof feature.traverseNodes === 'function'
    ) {
      node = await feature.traverseNodes(node, callback);
    }
  }

  if (node && node.nodeName && node.nodeName.toUpperCase() === 'A' && node.href) {
    let localObjectUrl = '';
    let objectEnglishUrl = '';
    // url is absolute
    if (node.href.includes('http')) {
      objectEnglishUrl = node.href.replaceAll(
        global_variables.requested_domain,
        global_variables.domain
      );
    } else {
      objectEnglishUrl = `${global_variables.domain}/${node.href}`;
    }
    localObjectUrl = getLocalObjectUrl(objectEnglishUrl);

    if (localObjectUrl) {
      node.setAttribute('href', localObjectUrl);
    } else {
      var unLocalizedUrl = document.getElementsByClassName('dota-unlocalized-url');
      if (unLocalizedUrl.length > 0) {
        // elements with class "dota-unlocalized-url" exist
      } else {
        let localLink = replaceEnglishDomainWithLocalDomain({
          url: node.href,
          englishDomain: global_variables.domain,
          localDomain: global_variables.requested_domain
            .replace(/(?<!:)\/{2,}/g, '/')
            .replace(/\/+$/, '')
        });

        if (global_variables.linkedDomains) {
          global_variables.linkedDomains.forEach((linkedDomain) => {
            localLink = replaceEnglishDomainWithLocalDomain({
              url: localLink,
              englishDomain: linkedDomain.domain,
              localDomain: linkedDomain.subdomain
            });
          });
        }
        if (localLink && localLink !== node.href) {
          node.setAttribute('href', localLink);
        }
      }
    }
  }

  if (node && node.nodeName && node.nodeName.toUpperCase() === 'A' && node.title) {
    addNodeToArray(node.title, node, 'title');
  }

  if (node && node.nodeName && node.nodeName.toUpperCase() === 'SELECT' && node.title) {
    addNodeToArray(node.title, node, 'title');
  }

  if (node && node.nodeName && node.nodeName.toUpperCase() === 'OPTGROUP' && node.label) {
    addNodeToArray(node.label, node, 'label');
  }

  if (node.childNodes && node.childNodes.length > 0) {
    if (!blacklistedTags.includes(node.nodeName.toUpperCase())) {
      if (node.getAttribute('class') === 'optionElement') {
        return true;
      }

      if (global_variables.enable_blacklist) {
        const checkBlackList = node_blacklist(node);
        if (checkBlackList) return;
      }

      if (
        node &&
        node.getAttribute &&
        (node.getAttribute('id') === 'devnagri_outermostdiv' ||
          node.getAttribute('id') === 'devnagri_dropdown' ||
          node.getAttribute('id') === 'newstab' ||
          node.getAttribute('id') === 'photostab')
      ) {
        return;
      }

      if (isNodeASentence(node)) {
        const parentId = getXPathForElement(node, document);
        if (!global_variables.sentence_nodes[parentId]) {
          global_variables.sentence_nodes[parentId] = {
            parentNode: node,
            children: []
          };
        }

        /**
         * -- SPA translations start
         * to handle SPA, update spa_sentence_nodes
         */
        if (global_variables.enable_spa_support && !global_variables.spa_sentence_nodes[parentId]) {
          global_variables.spa_sentence_nodes[parentId] = {
            parentNode: node,
            children: []
          };
        }
        // -- SPA translations end --

        for (const [index, innerNode] of node.childNodes.entries()) {
          await traverseNodes(innerNode, callback, true, getXPathForElement(node, document), index);
        }
      } else {
        for (const innerNode of node.childNodes) {
          await traverseNodes(
            innerNode,
            callback,
            isNodeASentenceChildren,
            sentenceParentId,
            subSentencePosition
          );
        }
      }
    }
  } else if (callback)
    callback(node, isNodeASentenceChildren, sentenceParentId, subSentencePosition);
}

// start whole dom translation
function startTraversingNow() {
  // New Code add here : ----------

  if (global_variables.enable_whitelist) {
    for (const whitelistId of global_variables.whitelist_ids) {
      const element = document.getElementById(whitelistId);
      if (element) traverseNodes(element, checkNodeType);
    }

    console.log('whitelist_class', global_variables.whitelist_classes);
    for (const whitelistClass of global_variables.whitelist_classes) {
      console.log('whitelist_class', whitelistClass);
      const whiteListElements = document.getElementsByClassName(whitelistClass);
      for (let i = 0; i < whiteListElements.length; i++) {
        console.log('whiteListElement', i);
        if (whiteListElements[i]) traverseNodes(whiteListElements[i], checkNodeType);
      }
    }
  } else {
    traverseNodes(document.head, checkNodeType);
    traverseNodes(document.body, checkNodeType);
  }
  // traverseNodes(document.head, checkNodeType);
  // traverseNodes(document.body, checkNodeType);
}

// this function is used to add english text as key in object(global_variables.arr_nodes)
function addNodeToArray(
  text,
  node,
  att,
  isNodeASentenceChildren = false,
  sentenceParentId = '',
  subSentencePosition,
  attributeKeyName = ''
) {
  if (checkIfStringIsLocal(text, true)) {
    return;
  }

  if (!isNodeASentenceChildren && isTextTranslatedText(text)) {
    return;
  }
  // let arr = typeof isNodeASentenceChildren === 'string' ? isNodeASentenceChildren : false;

  if (!checkIfSegmentIsValid(text.trim())) {
    return;
  }

  let txt = text.replaceAll('\n', ' ');
  txt = replaceNbsps(txt);
  txt = txt.trim();
  let translatedText = sourceLanguageCleanString(txt);

  if (isNodeASentenceChildren) {
    if (
      global_variables.sentence_nodes[sentenceParentId] &&
      global_variables.sentence_nodes[sentenceParentId].children
    ) {
      global_variables.sentence_nodes[sentenceParentId] = {
        children: global_variables.sentence_nodes[sentenceParentId].children.find(
          (item) =>
            item.subSentencePosition === subSentencePosition &&
            sourceLanguageCleanString(item.original_text).toLowerCase() ===
              sourceLanguageCleanString(text).toLowerCase()
        )
          ? global_variables.sentence_nodes[sentenceParentId].children
          : [
              ...global_variables.sentence_nodes[sentenceParentId].children,
              // eslint-disable-next-line prettier/prettier
              {
                node,
                text: sourceLanguageCleanString(text),
                translated_text: sourceLanguageCleanString(text),
                original_text: text,
                subSentencePosition
              }
            ],
        parentNode: global_variables.sentence_nodes[sentenceParentId].parentNode
      };
      const combinedOriginalText = getSentenceCombinedOriginalText(
        global_variables.sentence_nodes[sentenceParentId]
      );
      global_variables.sentence_nodes[sentenceParentId].original_text = combinedOriginalText;
      global_variables.sentence_nodes[sentenceParentId]._idx = global_variables.serialCount++;

      const combinedTranslatedText =
        global_variables.translation_api_global_data[combinedOriginalText];

      if (combinedTranslatedText) {
        global_variables.sentence_nodes[sentenceParentId].translated_text = combinedTranslatedText;

        /**
         * -- variable translations start
         * handle variables translation for sentence_nodes
         */
        if (
          global_variables.enable_variable_system &&
          global_variables.sentenceVariables[sentenceParentId] &&
          global_variables.sentenceVariables[sentenceParentId].variables.length > 0
        ) {
          const variableData = global_variables.sentenceVariables[sentenceParentId].variables[0];
          if (variableData.reconstructionTemplate) {
            const replacedTranslation = replaceVariablesTranslation(
              combinedOriginalText,
              global_variables.sentenceVariables[sentenceParentId],
              variableData
            );
            if (replacedTranslation) {
              if (
                global_variables.sentence_nodes[sentenceParentId].translated_text !== 'undefined'
              ) {
                global_variables.sentence_nodes[sentenceParentId].translated_text =
                  replacedTranslation.translatedTextWithSpaces;
                global_variables.sentence_nodes[sentenceParentId].original_text =
                  replacedTranslation.origText;
              }
            }
          }
        }
        // -- variable translations end --

        setTranslatedContentToSentenceParent(global_variables.sentence_nodes[sentenceParentId]);
      }
    }
    return;
  }

  const xpath = getXPathForElement(node, document, att);
  const xpathParent = getXPathForElement(node.parentElement, document);

  if (global_variables.translation_api_global_data[sourceLanguageCleanString(text)]) {
    translatedText = global_variables.translation_api_global_data[sourceLanguageCleanString(text)];

    const origText = sourceLanguageCleanString(text, false);
    const trimmedText = sourceLanguageCleanString(text);
    const translatedTextWithSpaces = origText.replace(trimmedText, translatedText);
    setTranslatedTextToElement(
      node,
      translatedTextWithSpaces,
      origText,
      false,
      '',
      xpath,
      document
    );
  }

  /**
   * -- variable translations start
   * handle variables translation for arr_nodes
   */
  if (
    global_variables.enable_variable_system &&
    text &&
    global_variables.sentenceVariables[xpath] &&
    global_variables.sentenceVariables[xpath].variables.length > 0
  ) {
    if (global_variables.sentenceVariables[xpath].variables[0].reconstructionTemplate) {
      const sentences = global_variables.sentenceVariables[xpath];
      const variables = sentences.variables[0];
      const replacedTranslation = replaceVariablesTranslation(text, sentences, variables);
      if (replacedTranslation) {
        setTranslatedTextToElement(
          node,
          replacedTranslation.translatedTextWithSpaces,
          replacedTranslation.origText,
          false,
          '',
          xpath,
          document
        );
      }
    }
  }
  // -- variable translations end --

  // console.log('xpathsss', xpath);

  // const dataToWrite = JSON.stringify(xpath, null, 2);

  // // Specify the file path and name
  // const filePath = 'XpathFromSDK.txt';

  // try {
  //   // Write the data to the file synchronously
  //   fs.writeFileSync(filePath, dataToWrite);
  //   console.log('Data written to file successfully.');
  // } catch (error) {
  //   console.error('Error writing data to file:', error);
  // }

  if (!Object.keys(global_variables.arr_nodes).includes(xpath)) {
    global_variables.arr_nodes[`${xpath}`] = {
      translatedText,
      originalText: sourceLanguageCleanString(text),
      nodes: [node],
      xpath: xpath,
      xpathParent: xpathParent,
      xpathChild: [],
      _idx: global_variables.serialCount++,
      attributeKeyName,
      // eslint-disable-next-line prettier/prettier
      ...(isNodeASentenceChildren
        ? {
            isNodeASentenceChildren,
            sentenceParentId: [sentenceParentId],
            subSentencePosition: [subSentencePosition]
          }
        : {})
    };
  } else {
    // node is already in arr_nodes
    if (isNodeASentenceChildren) {
      // text already exists in a node as sentence children
      if (global_variables.arr_nodes[`${xpath}`].isNodeASentenceChildren) {
        if (!global_variables.arr_nodes[`${xpath}`].sentenceParentId.includes(sentenceParentId)) {
          global_variables.arr_nodes[`${xpath}`].sentenceParentId.push(sentenceParentId);
          global_variables.arr_nodes[`${xpath}`].subSentencePosition.push(subSentencePosition);
        }
      } else {
        global_variables.arr_nodes[`${xpath}`].isNodeASentenceChildren = isNodeASentenceChildren;
        global_variables.arr_nodes[`${xpath}`].sentenceParentId = [sentenceParentId];
        global_variables.arr_nodes[`${xpath}`].subSentencePosition = [subSentencePosition];
      }
    }
    global_variables.arr_nodes[`${xpath}`].nodes.push(node);
  }
}

async function checkIfTraversingCompleted() {
  await updateNodesWithTranslatedText(
    global_variables.translation_api_global_data,
    global_variables.translationData.sentences
  );
  updateGlobalData();
}

// this function is used for set the hindi text in object(nodeTextObject)
async function updateNodesWithTranslatedText(translationData, xpathFromApi) {
  const result = {};

  xpathFromApi &&
    xpathFromApi.length > 0 &&
    xpathFromApi.forEach((obj) => {
      result[obj.sentence.toLowerCase() + obj.xpath] = obj.translation;
    });

  // let r = {};
  // xpathFromApi.sentences.forEach((obj) => {
  //   for (const key in obj) {
  //     r[key] = obj[key];
  //   }
  // });
  // console.log('rrdee', r, typeof r);

  // let result = {};
  // xpathFromApi.sentences &&
  //   xpathFromApi.sentences.length > 0 &&
  //   xpathFromApi.sentences.map((value, index) => {
  //     result = value[index];
  //   });
  // console.log('rrrr', result);

  const nodesCount = Object.keys(global_variables.arr_nodes).length;
  if (!result || nodesCount == 0) {
    return;
  }
  const missingTranslations = [];
  for (const [key, val] of Object.entries(global_variables.arr_nodes)) {
    let txtOriginal = val.originalText;
    // console.log('txtOriginal', txtOriginal);
    if (txtOriginal.startsWith(global_variables.meta_prefix)) {
      txtOriginal = txtOriginal.replace(global_variables.meta_prefix, '');
    }
    txtOriginal = txtOriginal.trim().toLowerCase();
    // if (translationData[txtOriginal] && global_variables.arr_nodes[txtOriginal]) {
    //   global_variables.arr_nodes[txtOriginal].translatedText = translationData[txtOriginal];
    // }

    if (result[txtOriginal + key] && global_variables.arr_nodes[key]) {
      global_variables.arr_nodes[key].translatedText = result[txtOriginal + key];
    } else if (!missingTranslations.includes(key)) {
      if (sourceLanguageCleanString(key).length > 0) {
        missingTranslations.push(key);
      }
      // missingTranslations.push(key);
    }
    // console.log('global_variables.arr_nodes', global_variables.arr_nodes);
  }

  let cleanedMissingTranslation = missingTranslations.map((x) => sourceLanguageCleanString(x));

  cleanedMissingTranslation = cleanedMissingTranslation.filter((item) => item.length > 1);

  translate(global_variables.selected_lang_code, result);

  const missingPercentage = (cleanedMissingTranslation.length * 100) / nodesCount;

  // await updatePageCache();

  // Call Get Api again if missing text percentage is more than 5 %, Can be adjusted as per requirements
  if (missingPercentage > global_variables.missingTranslationPercentageOffsetForRetry) {
    // There are some pending translation Object in global_variables.arr_nodes, so will call get api after few seconds to get updated text
    // call get translation api after 1.5 second
    global_variables.translation_api_retry_counter--;
    if (global_variables.translation_api_retry_counter > 0) {
      setTimeout(async () => {
        GetTranslationsApi();
      }, 1500);
    }
  }
}

// this function is used for setting hindi text in html dom
function translate(langCode, result) {
  if (
    global_variables.arr_nodes !== 'undefined' &&
    global_variables.translation_api_global_data != false
  ) {
    if (langCode != global_variables.source_lang_code) {
      // console.log('global_variables.arr_nodes', global_variables.arr_nodes);
      // selected language is not the same as page source language

      // for (const node in global_variables.arr_nodes) {
      //   console.log('nodesss', global_variables.arr_nodes[node].originalText, 'tytptrit', typeof global_variables.arr_nodes);
      // }

      // for (const node in global_variables.arr_nodes) {
      //   setTranslatedTextToNode({
      //     node
      //     // ...global_variables.arr_nodes[`${cleanString(global_variables.arr_nodes[node].originalText)}`]
      //     // disableOrigTextCheck: true,
      //   });
      // }

      // console.log('global_variables.arr_nodes', global_variables.arr_nodes);
      // for (const key of Object.keys(global_variables.arr_nodes)) {
      //   setTranslatedTextToNode({
      //     ...global_variables.arr_nodes[key]
      //     // disableOrigTextCheck: true,
      //   });
      // }

      for (const [key, values] of Object.entries(global_variables.arr_nodes)) {
        setTranslatedTextToNode({
          ...global_variables.arr_nodes[key],
          translatedText: values.translatedText,
          xpath: values.xpath
          // disableOrigTextCheck: true,
        });
      }
    } else {
      // convert the translation back to the original lanaguages

      // eslint-disable-next-line no-unused-vars
      // for (const key of Object.keys(global_variables.arr_nodes)) {
      //   for (const key of Object.keys(global_variables.arr_nodes)) {
      //     setTranslatedTextToNode({
      //       ...global_variables.arr_nodes[`${cleanString(key)}`],
      //       translatedText: `${cleanString(key)}`,
      //       disableOrigTextCheck: true
      //     });
      //   }
      // }

      // for (const [key, values] of Object.entries(global_variables.arr_nodes)) {
      for (const [key, values] of Object.entries(global_variables.arr_nodes)) {
        setTranslatedTextToNode({
          ...global_variables.arr_nodes[key],
          // translatedText: values.translatedText,
          translatedText: values.originalText,
          xpath: values.xpath,
          disableOrigTextCheck: true
        });
      }
      // translate sentence nodes back to english
      Object.keys(global_variables.sentence_nodes).forEach((key) => {
        let children = [];
        if (global_variables.sentence_nodes[key] && global_variables.sentence_nodes[key].children) {
          children = global_variables.sentence_nodes[key].children;
        }
        if (children && Array.isArray(children)) {
          children.forEach((child) => {
            const originalText = child.original_text;
            if (child.node) {
              setTranslatedTextToNode({
                nodes: [child.node],
                originalText,
                translatedText: `${sourceLanguageCleanString(originalText)}`,
                xpath: child.xpath,
                disableOrigTextCheck: true
              });
            }
          });
        }
      });

      /**
       * -- SPA translations start
       * in case of SPA - translate sentence nodes back to english
       */
      if (global_variables.enable_spa_support) {
        Object.keys(global_variables.spa_sentence_nodes).forEach((key) => {
          let children = [];
          if (
            global_variables.spa_sentence_nodes[key] &&
            global_variables.spa_sentence_nodes[key].children
          ) {
            children = global_variables.spa_sentence_nodes[key].children;
          }
          if (children && Array.isArray(children)) {
            children.forEach((child) => {
              const originalText = child.original_text;
              if (child.node) {
                setTranslatedTextToNode({
                  nodes: [child.node],
                  originalText,
                  translatedText: `${sourceLanguageCleanString(originalText)}`,
                  xpath: child.xpath,
                  disableOrigTextCheck: true
                });
              }
            });
          }
        });
      }
      // -- SPA translations end --
    }
  }
  global_variables.metatag_arr.TITLE = {
    ...global_variables.metatag_arr.TITLE,
    translatedText: document.title
  };
}

// this function is used for setting hindi text in html dom
function setTranslatedTextToNode(node) {
  node.nodes.forEach((element) => {
    const { originalText } = node;
    const trimmedText = originalText.trim();
    const { translatedText } = node;
    const translatedTextWithSpaces = originalText.replace(trimmedText, translatedText);
    const xpathFromApi = node.xpath;

    // let currentXpath = getXPathForElement(element, document);
    setTranslatedTextToElement(
      element,
      translatedTextWithSpaces,
      originalText,
      node.disableOrigTextCheck,
      node.attributeKeyName,
      xpathFromApi,
      document
    );
  });
}

// this function is used for setting hindi text to html dom element
function setTranslatedTextToElement(
  element,
  translatedText,
  originalText,
  disableOrigTextCheck = false,
  attributeKeyName = '',
  xpathFromApi,
  serverDocument
) {
  const document = serverDocument || document;
  let currentXpath = getXPathForElement(element, document);
  let xpathApi = xpathFromApi?.length > 0;
  let xpathCurrent = currentXpath?.length > 0;
  if (!xpathApi || !xpathCurrent) {
    return;
  }

  if (global_variables.selected_lang_code !== global_variables.source_lang_code) {
    if (
      isTextTranslatedText(sourceLanguageCleanString(originalText)) ||
      (isTextTranslatedText(element.textContent) &&
        element.nodeName &&
        !['META', 'INPUT', 'TEXTAREA', 'IMG'].includes(element.nodeName))
    ) {
      return;
    }
  }

  if (attributeKeyName === 'title') {
    currentXpath = getXPathForElement(element, document, 'title');
    if (originalText && element && element.title) {
      if (
        sourceLanguageCleanString(element.title.trim().toLowerCase()) ===
          (sourceLanguageCleanString(originalText.trim().toLowerCase()) || disableOrigTextCheck) &&
        xpathFromApi &&
        xpathFromApi === currentXpath
      ) {
        element.title = translatedText;
      }
    }
    return;
  }

  if (element.nodeName == 'INPUT') {
    if (element.type === 'button') {
      // if (element.value === originalText && xpathFromApi === currentXpath) {
      if (
        (element.value === originalText || disableOrigTextCheck) &&
        xpathFromApi === currentXpath
      ) {
        element.value = translatedText;
      }
    } else {
      if (element.type === 'submit') {
        // if (element.value === originalText && xpathFromApi === currentXpath) {
        if (
          (element.value === originalText || disableOrigTextCheck) &&
          xpathFromApi === currentXpath
        ) {
          element.value = translatedText;
        }
      }

      currentXpath = getXPathForElement(element, document, 'placeholder');
      // if (element.placeholder === originalText && xpathFromApi === currentXpath) {
      if (
        (element.placeholder === originalText || disableOrigTextCheck) &&
        xpathFromApi === currentXpath
      ) {
        element.placeholder = translatedText;
      }

      currentXpath = getXPathForElement(element, document, 'title');
      // if (element.title && element.title === originalText && xpathFromApi === currentXpath) {
      if (
        element.title &&
        (element.title === originalText || disableOrigTextCheck) &&
        xpathFromApi === currentXpath
      ) {
        element.title = translatedText;
      }
    }
  } else if (element.nodeName == 'META') {
    if (
      (element.name &&
        ['DESCRIPTION', 'TITLE', 'KEYWORDS', 'TWITTER:TITLE', 'TWITTER:DESCRIPTION'].includes(
          element.name.toUpperCase()
        )) ||
      (element.getAttribute('property') &&
        ['OG:TITLE', 'OG:DESCRIPTION'].includes(element.getAttribute('property').toUpperCase()))
    ) {
      if (translatedText.startsWith(global_variables.meta_prefix)) {
        translatedText = translatedText.replace(global_variables.meta_prefix, '');
      }
      element.content = translatedText;
      // meta editor
      if (element.name) {
        global_variables.metatag_key = `${element.nodeName}-${element.name}`;
        global_variables.metatag_arr[`${global_variables.metatag_key}`] = {
          ...global_variables.metatag_arr[`${global_variables.metatag_key}`],
          content: element.content,
          translatedText: element.content
        };
      } else if (element.getAttribute('property')) {
        global_variables.metatag_key = `${element.nodeName}-${element.getAttribute('property')}`;
        global_variables.metatag_arr[`${global_variables.metatag_key}`] = {
          ...global_variables.metatag_arr[`${global_variables.metatag_key}`],

          content: element.content,
          translatedText: element.content
        };
      }
    }
  } else if (element.nodeName == 'TEXTAREA') {
    currentXpath = getXPathForElement(element, document, 'placeholder');
    if (
      element.placeholder &&
      // eslint-disable-next-line prettier/prettier
      // element.placeholder.replaceAll('\n', '').trim().toLowerCase() ===
      //   originalText.replaceAll('\n', '').trim().toLowerCase() &&
      (element.placeholder.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase() ||
        disableOrigTextCheck) &&
      xpathFromApi === currentXpath
    ) {
      element.placeholder = translatedText;
    }

    currentXpath = getXPathForElement(element, document, 'title');
    if (
      element.title &&
      // eslint-disable-next-line prettier/prettier
      // element.title.replaceAll('\n', '').trim().toLowerCase() ===
      //   originalText.replaceAll('\n', '').trim().toLowerCase() &&
      (element.title.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase() ||
        disableOrigTextCheck) &&
      xpathFromApi === currentXpath
    ) {
      element.title = translatedText;
    }
  } else if (element.nodeName == 'IMG') {
    if (translatedText.startsWith(global_variables.meta_prefix) && xpathFromApi === currentXpath) {
      translatedText = translatedText.replace(global_variables.meta_prefix, '');
    }
    if (originalText.startsWith(global_variables.meta_prefix)) {
      originalText = originalText.replace(global_variables.meta_prefix, '');
    }
    currentXpath = getXPathForElement(element, document, 'alt');
    if (
      element.alt &&
      // eslint-disable-next-line prettier/prettier
      // element.alt.replaceAll('\n', '').trim().toLowerCase() ===
      //   originalText.replaceAll('\n', '').trim().toLowerCase() &&
      (element.alt.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase() ||
        disableOrigTextCheck) &&
      xpathFromApi === currentXpath
    ) {
      element.alt = translatedText;
    }
    currentXpath = getXPathForElement(element, document, 'title');
    if (
      element.title &&
      // element.title.replaceAll('\n', '').trim().toLowerCase() ===
      //   originalText.replaceAll('\n', '').trim().toLowerCase() &&
      (element.title.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase() ||
        disableOrigTextCheck) &&
      xpathFromApi === currentXpath
    ) {
      element.title = translatedText;
    }
  } else if (element.nodeName == 'SELECT') {
    currentXpath = getXPathForElement(element, document, 'title');
    if (
      element.title &&
      // element.title.replaceAll('\n', '').trim().toLowerCase() ===
      //   originalText.replaceAll('\n', '').trim().toLowerCase() &&
      (element.title.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase() ||
        disableOrigTextCheck) &&
      xpathFromApi === currentXpath
    ) {
      element.title = translatedText;
    }
  } else if (element.nodeName == 'OPTION') {
    currentXpath = getXPathForElement(element, document, 'label');
    if (
      element.label &&
      // element.label.replaceAll('\n', '').trim().toLowerCase() ===
      //   originalText.replaceAll('\n', '').trim().toLowerCase() &&
      (element.label.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase() ||
        disableOrigTextCheck) &&
      xpathFromApi === currentXpath
    ) {
      element.label = translatedText;
    }
  } else if (element.nodeName == 'OPTGROUP') {
    currentXpath = getXPathForElement(element, document, 'label');
    if (
      element.label &&
      // element.label.replaceAll('\n', '').trim().toLowerCase() ===
      //   originalText.replaceAll('\n', '').trim().toLowerCase() &&
      (element.label.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase() ||
        disableOrigTextCheck) &&
      xpathFromApi === currentXpath
    ) {
      element.label = translatedText;
    }
  } else if (element.nodeName == 'A') {
    currentXpath = getXPathForElement(element, document, 'title');
    if (
      element.title &&
      // element.title.replaceAll('\n', '').trim().toLowerCase() ===
      //   originalText.replaceAll('\n', '').trim().toLowerCase() &&
      (element.title.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase() ||
        disableOrigTextCheck) &&
      xpathFromApi &&
      xpathFromApi === currentXpath
    ) {
      element.title = translatedText;
    }
  } else {
    if (translatedText.startsWith(global_variables.meta_prefix)) {
      translatedText = translatedText.replace(global_variables.meta_prefix, '');
    }
    if (originalText.startsWith(global_variables.meta_prefix)) {
      originalText = originalText.replace(global_variables.meta_prefix, '');
    }

    if (
      (sourceLanguageCleanString(element.textContent.toLowerCase()) ===
        sourceLanguageCleanString(originalText.toLowerCase()) &&
        xpathFromApi === currentXpath) ||
      disableOrigTextCheck ||
      isLiveJs()
    ) {
      // to prevent triggering of DOMCharacterDataModified event even if we have english text.
      if (
        (sourceLanguageCleanString(originalText) != sourceLanguageCleanString(translatedText) &&
          checkIfStringIsLocal(translatedText, true)) ||
        disableOrigTextCheck
      ) {
        // element.textContent = translatedText;
        if (xpathFromApi === currentXpath) {
          element.textContent = translatedText;
        }
      }

      // element.textContent = translatedText;
    }
  }
}

async function domNodeInsertedHandler(event) {
  if (event && event.target && event.target.parentElement) {
    if (global_variables.enable_blacklist) {
      const { parentElement } = event.target;
      // node blacklist by class condition
      if (
        global_variables.blacklist_classes
          .map(
            (item) =>
              parentElement &&
              parentElement.getAttribute &&
              parentElement.getAttribute('class') &&
              parentElement.getAttribute('class').includes(item)
          )
          .some(Boolean)
      ) {
        return;
      }

      // node blacklist by id condition
      if (
        parentElement &&
        parentElement.getAttribute &&
        parentElement.getAttribute('id') &&
        global_variables.blacklist_ids.includes(parentElement.getAttribute('id'))
      ) {
        return;
      }
    }
  }

  const drawingNodes = ['RECT', 'PATH', 'G', 'CLIPPATH'];

  if (drawingNodes.includes(event.target.nodeName.toUpperCase())) {
    return;
  }

  // eslint-disable-next-line prettier/prettier
  const unformattedFullUrl = isProxy()
    ? document.URL.replaceAll(
        global_variables.config.requested_domain,
        global_variables.config.domain
      )
    : document.URL;

  const urlObj = new URL(document.URL);
  urlObj.searchParams.delete('mode');
  urlObj.searchParams.delete('dota-token');
  urlObj.searchParams.delete('language_code');

  const urlString = urlObj.toString();

  const fullURL = isProxy()
    ? urlString.replaceAll(global_variables.config.requested_domain, global_variables.config.domain)
    : urlString;

  if (
    event.target &&
    event.target.parentElement &&
    event.target.parentElement.nodeName == 'STYLE'
  ) {
    return;
  }

  if (global_variables.document_url_full !== unformattedFullUrl) {
    global_variables.document_url_full = fullURL;
    global_variables.translation_api_url = `${global_variables.api_baseurl}/translations?url=${global_variables.document_url_full}`;

    /**
     * -- SPA translations start
     * if we got issue with isProxy() enabled websites, then we will add isSPA check from api.
     */
    global_variables.arr_nodes = {};
    global_variables.sentence_nodes_by_orig_text = {};

    // in case of SPA, assign all sentance_nodes only on first load to keep them available through out all the pages
    if (global_variables.enable_spa_support && global_variables.spa_first_load_flag) {
      global_variables.spa_sentence_nodes = global_variables.sentence_nodes;
    }
    // -- SPA translations end --

    global_variables.sentence_nodes = {};

    global_variables.document_url = document.URL;
    global_variables.translation_api_retry_counter = 1;
    global_variables.missingTranslationPercentageOffsetForRetry = 1;
    global_variables.traverse_whole_dom = true;
    await startTraversingNow();
    GetTranslationsApi();

    if (isProxy() || global_variables.status_api_global_data.enableSubdomainRedirect) {
      callApiRequest(() => subscriptionStatusApi(global_variables.status_api_url));
    }
  }
  global_variables.translation_api_retry_counter = 1;
  global_variables.missingTranslationPercentageOffsetForRetry = 1;

  if (!global_variables.enable_spa_support || wep.isEnabled() || idfcfa.isEnabled()) {
    traverseNodes(event.target, checkNodeType);
  }

  await checkIfTraversingCompleted();
}

function translateAllFramesOnPage() {
  const frameElements = document.querySelectorAll('iframe');
  frameElements.forEach((element) => {
    if (element && element.contentDocument && element.contentDocument.body) {
      const frameId = element.getAttribute('id');
      if (frameId && global_variables.frame_ids.includes(frameId)) {
        if (global_variables.frames_translation_status_map[frameId] === true) {
          if (element.contentDocument.body.childElementCount > 0) {
            const obj = { target: element.contentDocument.body };
            domNodeInsertedHandler(obj);
            global_variables.frames_translation_status_map[frameId] = false;
          }
        }
      }
    }
  });
}

// sentence merging functions
// function getXPathForElement(el, xml) {
//   let xpath = '';
//   let pos;
//   let tempitem2;

//   while (el !== xml.documentElement) {
//     pos = 0;
//     tempitem2 = el;
//     while (tempitem2) {
//       if (tempitem2.nodeType === 1 && tempitem2.nodeName === el.nodeName) {
//         // If it is ELEMENT_NODE of the same name
//         pos += 1;
//       }
//       tempitem2 = tempitem2.previousSibling;
//     }
//     // eslint-disable-next-line prettier/prettier
//     xpath = `*[name()='${el.nodeName}' and namespace-uri()='${
//       el.namespaceURI ?? ''
//     }'][${pos}]/${xpath}`;

//     el = el.parentNode;
//   }
//   // eslint-disable-next-line prettier/prettier
//   xpath = `/*[name()='${xml.documentElement.nodeName}' and namespace-uri()='${
//     el.namespaceURI ?? ''
//   }']/${xpath}`;
//   xpath = xpath.replace(/\/$/, '');
//   return xpath;
// }

// in context editor when web owner edit anything
function DataAttributeDataChangeListioner() {
  const element = document.querySelector('.devnagri_unsavedChanges');
  if (element) {
    element.remove();
  }
  const divForUnSavedChange = document.createElement('div');
  divForUnSavedChange.innerText = 'You have unsaved changes';
  divForUnSavedChange.classList.add('devnagri_unsavedChanges');
  divForUnSavedChange.id = 'devnagri_optionElementt';
  const outerMostDiv = document.getElementById('devnagri_outermostdiv');
  outerMostDiv.appendChild(divForUnSavedChange);
}

// this will remove event listener
function RemoveDataAttributeDataChangeListioner() {
  window.removeEventListener('DOMCharacterDataModified', DataAttributeDataChangeListioner);
}

// this will add event listener when web owner edit any text
function AddDataAttributeDataChangeListioner() {
  if (global_variables.web_owner) {
    RemoveDataAttributeDataChangeListioner();
    window.addEventListener('DOMCharacterDataModified', DataAttributeDataChangeListioner);
  }
}

function contentEditt(node) {
  global_variables.edit_mode_enabled = true;
  // handling the script tag and other unnecesaary text
  const blacklistedTags = ['IMG', 'VIDEO', 'IFRAME', 'NOSCRIPT', 'STYLE', 'SCRIPT'];
  if (node.childNodes && node.childNodes.length > 0) {
    if (!blacklistedTags.includes(node.nodeName.toUpperCase())) {
      for (const innerNode of node.childNodes) {
        contentEditt(innerNode);
      }
    }
  } else {
    if (
      node.parentElement.getAttribute('id') === 'devnagri_optionElementt' ||
      node.parentElement.getAttribute('class') === 'devnagri_optionElement' ||
      node.parentElement.getAttribute('devnagri_option') === 'devnagri_optionElement' ||
      node.parentElement.getAttribute('id') === 'devnagri_seo_button_id'
    ) {
      return true;
    }
    switch (node.nodeName) {
      case '#text': {
        if (node.textContent.trim().length != 0) {
          if (node.parentElement.nodeName !== 'BODY') {
            // english_attr atrribute will contains english text for it's corresponding translated text
            node.parentElement.setAttribute('contentEditable', 'true');
          }
        }
        break;
      }
    }
  }
}

// showing unauthorized message
function uiErrorContextEditor() {
  const element = document.getElementById('devnagri_uiErrorContextEditor');
  if (element) {
    element.remove();
  }
  const uiErrorEditor = document.createElement('div');
  uiErrorEditor.innerText = 'You are unauthorized please use new token';
  uiErrorEditor.classList.add('devnagri_uiErrorEditor');
  uiErrorEditor.id = 'devnagri_uiErrorContextEditor';
  const outerMostDiv = document.getElementById('devnagri_outermostdiv');
  outerMostDiv.appendChild(uiErrorEditor);
}

// this will create ui for unauthorized message
function showErrorMessage() {
  uiErrorContextEditor();
}

// this function will call the save -translation patch api for sending newly added edited text to server
async function contextEditorPatchApi(loader) {
  try {
    if (!global_variables._access_token) {
      return;
    }

    if (
      global_variables.url_object.searchParams.has('dota-token') &&
      global_variables.url_object.searchParams.has('language_code')
    ) {
      global_variables.document_url =
        global_variables.url_object.origin + global_variables.url_object.pathname;
      if (!global_variables.document_url.endsWith('/')) {
        global_variables.document_url += '/';
      }
    }
    const res = await fetch(global_variables.context_editor_api, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${global_variables.dota_token}`
      },
      body: JSON.stringify({
        url: global_variables.document_url,
        language_code: global_variables.selected_lang_code,
        translations: global_variables.content_editable
      })
    });
    if (res.status == 401) {
      showErrorMessage();
      if (loader) {
        loader.remove();
      }
      global_variables.unAuthApiResponse = true;
    }
    const data = await res.json();
    return data;
  } catch (error) {
    throw new Error('save-translation response-> you are unauthorized');
  }
}

// this function willl call patch api for sending edited meta tag
function seoEditedText() {
  for (const key of Object.keys(global_variables.metatag_arr)) {
    global_variables.content_editable.push({
      sentence: global_variables.metatag_arr[`${key}`].engText,
      translation: global_variables.metatag_arr[`${key}`].translatedText
    });
  }
  contextEditorPatchApi();
  global_variables.content_editable.length = 0;
}

// this will create dynamically ui for seo so that seo thing meta and title can be edited
function seo_ui() {
  global_variables.seoUI = !global_variables.seoUI;
  if (!global_variables.seoUI) {
    const tag = document.querySelector('.devnagri_dynamic_container');
    tag.remove();
    return;
  }

  const body = document.getElementsByTagName('body')[0];
  const mainDiv = document.createElement('div');
  mainDiv.classList.add('devnagri_dynamic_container');
  body.appendChild(mainDiv);
  const div = document.createElement('div');
  div.classList.add('dynamic_container');
  mainDiv.appendChild(div);
  const divForheader = document.createElement('div');
  div.appendChild(divForheader);
  const h1 = document.createElement('h3');
  h1.innerText = global_variables.source_lang_code.toUpperCase();
  h1.classList.add('devnagri_h1_eng');
  h1.setAttribute('style', 'margin-bottom:0');
  divForheader.appendChild(h1);
  const h2 = document.createElement('h3');
  h2.setAttribute('style', 'margin-bottom:0');
  h2.classList.add('devnagri_h1_def_lang');
  h2.innerText = `${global_variables.default_lang_code.toUpperCase()} (Manual Translation)`;
  const iconForArrow = document.createElement('div');
  iconForArrow.classList.add('devnagri_divForArrow');
  divForheader.appendChild(iconForArrow);
  divForheader.appendChild(h2);

  for (const key of Object.keys(global_variables.metatag_arr)) {
    const divLabelInput = document.createElement('div');
    divLabelInput.classList.add('devnagri_div_lable_input');
    const label = document.createElement('label');
    label.innerText = key;
    label.classList.add('devnagri_label');
    const input = document.createElement('textarea');
    input.value = global_variables.metatag_arr[`${key}`].engText;
    input.rows = 4;
    input.cols = 30;
    input.placeholder = 'english text';
    input.setAttribute('disabled', true);
    input.classList.add('devnagri_form-control');
    const br = document.createElement('br');
    divLabelInput.appendChild(label);
    divLabelInput.appendChild(br);
    const divinput = document.createElement('div');
    divLabelInput.appendChild(divinput);
    const divforinputandfig = document.createElement('div');
    divforinputandfig.setAttribute('style', 'float:left');
    divforinputandfig.appendChild(input);
    divinput.appendChild(divforinputandfig);
    const editedinput = document.createElement('textarea');
    editedinput.classList.add('devnagri_form-control');
    editedinput.value = global_variables.metatag_arr[`${key}`].translatedText;
    editedinput.placeholder = 'Manual Text';
    editedinput.id = 'devnagri_textarea';
    editedinput.rows = 4;
    editedinput.cols = 30;
    editedinput.onchange = (e) => {
      global_variables.metatag_arr[`${key}`].translatedText = e.target.value;
    };
    editedinput.style.cssText = 'margin-left:15px;';
    const divforinputandfig2 = document.createElement('div');
    divforinputandfig2.setAttribute('style', 'float:left');
    divforinputandfig2.appendChild(editedinput);
    divinput.appendChild(divforinputandfig2);
    div.appendChild(divLabelInput);
  }
  const buttonSubmit = document.createElement('div');
  buttonSubmit.classList.add('devnagri_seo_submit');
  buttonSubmit.setAttribute('style', 'background-color:white');
  buttonSubmit.onclick = () => {
    global_variables.seoUI = !global_variables.seoUI;
    seoEditedText();
    div.remove();
    AddDataAttributeDataChangeListioner();
  };
  const buttonCancel = document.createElement('div');
  buttonCancel.classList.add('devnagri_seo_cancel');
  buttonCancel.setAttribute('style', 'background-color:white');
  buttonCancel.onclick = () => {
    global_variables.seoUI = !global_variables.seoUI;
    div.remove();
    global_variables.renderUICounter = 0;
  };
  const divForSendCancel = document.createElement('div');
  divForSendCancel.setAttribute('style', 'padding: 15px 12px;display: inline-block');
  divForSendCancel.appendChild(buttonSubmit);
  divForSendCancel.appendChild(buttonCancel);
  div.appendChild(divForSendCancel);
}

// this function will will compare the old object with new edited object and then save the edited thext into new object
function checkingContentEditable(node) {
  // handling the script tag and other unnecessary text
  const blacklistedTags = ['IMG', 'VIDEO', 'IFRAME', 'NOSCRIPT', 'STYLE', 'SCRIPT'];

  if (node.childNodes && node.childNodes.length > 0) {
    if (!blacklistedTags.includes(node.nodeName.toUpperCase())) {
      for (const innerNode of node.childNodes) {
        checkingContentEditable(innerNode);
      }
    }
  } else {
    if (
      node.parentElement.getAttribute('id') === 'devnagri_optionElementt' ||
      node.parentElement.getAttribute('class') === 'devnagri_optionElement' ||
      node.parentElement.getAttribute('devnagri_option') === 'devnagri_optionElement'
    ) {
      return true;
    }
    switch (node.nodeName) {
      case '#text': {
        if (node.textContent.trim().length != 0) {
          let txt = node.textContent.replaceAll('\n', ' ');
          txt = replaceNbsps(txt);

          txt = txt.trim();

          // node.parentElement.setAttribute("english_name", node.textContent);
          if (node.parentElement.getAttribute('english_text')) {
            var engSentence = node.parentElement.getAttribute('english_text').trim();
          }
          const editedTranslatedText = txt;

          const xpath = getXPathForElement(node, document);

          if (
            global_variables.arr_nodes[`${xpath}`] &&
            editedTranslatedText !== global_variables.arr_nodes[`${xpath}`].translatedText
          ) {
            global_variables.content_editable.push({
              sentence: engSentence,
              translation: editedTranslatedText
            });
          }
        }
        break;
      }

      case 'SCRIPT': {
        break;
      }
    }
  }
}

function saveChangesUI() {
  const element = document.querySelector('.devnagri_unsavedChanges');
  if (element) {
    element.remove();
  }
  if (!document.getElementById('devnagri_uiErrorContextEditor')) {
    const divForUnSavedChange = document.createElement('div');
    divForUnSavedChange.innerText = 'Changes Saved';
    divForUnSavedChange.classList.add('devnagri_savedChanges');
    divForUnSavedChange.id = 'devnagri_optionElementt';
    const outerMostDiv = document.getElementById('devnagri_outermostdiv');
    outerMostDiv.appendChild(divForUnSavedChange);
    // let body = document.getElementsByTagName("body")[0];
    // body.appendChild(divForUnSavedChange);
    setTimeout(() => {
      const element = document.querySelector('.devnagri_savedChanges');
      if (element) {
        element.remove();
      }
    }, 1000);
  }
}

// this function is used for sending the new edited text
async function webOwnerSend() {
  global_variables.changedContextEditor = true;
  checkingContentEditable(document.body);
  global_variables.content_editable = global_variables.content_editable.filter(
    (v, i, a) => a.findIndex((t) => t.sentence === v.sentence) === i
  );

  const loader = document.getElementById('Devnagri-loader');
  const button = document.querySelector('.devnagri_owner_send_button');
  if (global_variables.content_editable.length == 0) {
    if (loader) {
      loader.remove();
    }
    return;
  }
  const isSuccess = await contextEditorPatchApi(loader);
  if (isSuccess && isSuccess.message == 'success') {
    if (loader) {
      loader.remove();
    }
    for (let i = 0; i < global_variables.content_editable.length; i++) {
      global_variables.arr_nodes[
        `${global_variables.content_editable[i].sentence}`
      ].translatedText = global_variables.content_editable[i].translation;
    }
    for (let i = 0; i < global_variables.content_editable.length; i++) {
      setTranslatedTextToNode({
        ...global_variables.arr_nodes[`${global_variables.content_editable[i].sentence}`],
        translatedText: global_variables.content_editable[i].translation
      });
    }
    button.onclick = () => {
      if (!localStorage.getItem('devnagri_selected_lang_code')) {
        return;
      }
      global_variables.content_editable.length = 0;
      const oldArr = global_variables.content_editable.length;

      checkingContentEditable(document.body);

      if (oldArr != global_variables.content_editable.length) {
        const div = document.createElement('div');
        div.classList.add('Devnagri-ring');
        div.id = 'Devnagri-loader';
        // let body = document.getElementsByTagName("body")[0];
        // body.appendChild(div);
        let position = 'z-index: 2147483647;position:fixed;';
        let bottom;
        let right;
        let left;
        if (global_variables.ui_bottom) {
          bottom = `${parseInt(global_variables.ui_bottom) + 26}%`;
          position += `bottom:${bottom};`;
        }
        if (global_variables.ui_left) {
          left = `${parseInt(global_variables.ui_left) + 7}%`;
          position += `left:${left};`;
        }
        if (global_variables.ui_right) {
          right = `${parseInt(global_variables.ui_right) + 7}%`;
          position += `right:${right};`;
        }
        div.style.cssText = position;
        const outerMostDiv = document.getElementById('devnagri_outermostdiv');
        outerMostDiv.appendChild(div);

        webOwnerSend();
        saveChangesUI();
      }
    };
  }
}

// this function is used for reloading when text is edited but don't want to send text to api
function webOwnerCancel() {
  location.reload();
}

//  it will create ui button for send,cancel and seo icon
async function webOwner() {
  const div1 = document.createElement('div');
  div1.setAttribute('style', 'float:left');
  const seo_button = document.createElement('div');
  seo_button.classList.add('devnagri_seo');
  seo_button.id = 'devnagri_seo_button_id';
  seo_button.setAttribute('style', 'background-color:white');
  seo_button.innerHTML = seo_button_svg_content;
  seo_button.onclick = () => {
    seo_ui();
  };
  div1.appendChild(seo_button);
  global_variables.initialdiv.insertBefore(div1, global_variables.initialdiv.children[0]);
  const div2 = document.createElement('div');
  div2.setAttribute('style', 'float:left');
  const send_button = document.createElement('div');
  send_button.classList.add('devnagri_owner_send_button');
  send_button.setAttribute('style', 'background-color:white');
  send_button.id = 'devnagri_optionElementt';
  send_button.innerHTML = save_button_svg_content;

  send_button.onclick = () => {
    const body = document.getElementsByTagName('body')[0];
    checkingContentEditable(document.body);
    if (global_variables.content_editable.length && !global_variables.unAuthApiResponse) {
      const div = document.createElement('div');
      div.classList.add('Devnagri-ring');
      div.id = 'Devnagri-loader';
      let position = '  z-index: 2147483647;position:fixed;';
      let bottom;
      let right;
      let left;
      if (global_variables.ui_bottom) {
        bottom = `${parseInt(global_variables.ui_bottom) + 26}%`;
        position += `bottom:${bottom};`;
      }
      if (global_variables.ui_left) {
        left = `${parseInt(global_variables.ui_left) + 7}%`;
        position += `left:${left};`;
      }
      if (global_variables.ui_right) {
        right = `${parseInt(global_variables.ui_right) + 7}%`;
        position += `right:${right};`;
      }
      div.style.cssText = position;
      const outerMostDiv = document.getElementById('devnagri_outermostdiv');
      outerMostDiv.appendChild(div);
      // let outerMostDiv = document.getElementById("devnagri_outermostdiv");
      // outerMostDiv.appendChild(div);
      body.appendChild(div);
      saveChangesUI();
      webOwnerSend();
    }
  };
  div2.appendChild(send_button);
  global_variables.initialdiv.insertBefore(div2, global_variables.initialdiv.children[1]);
  const div3 = document.createElement('div');

  div3.setAttribute('style', 'float:left');
  const cancel_button = document.createElement('div');
  cancel_button.classList.add('devnagri_owner_cancel_button');
  cancel_button.innerHTML = close_button_svg_content;
  cancel_button.id = 'devnagri_optionElementt';
  cancel_button.setAttribute('style', 'background-color:white');
  cancel_button.onclick = () => webOwnerCancel();

  div3.appendChild(cancel_button);
  global_variables.initialdiv.insertBefore(div3, global_variables.initialdiv.children[2]);
  const verticalLineDiv = document.createElement('div');
  verticalLineDiv.setAttribute('style', 'float:left');
  const vl = document.createElement('div');
  vl.classList.add('devnagri_vl');
  vl.setAttribute('id', 'devnagri_vl_seo');
  verticalLineDiv.appendChild(vl);
  global_variables.initialdiv.insertBefore(
    verticalLineDiv,
    global_variables.initialdiv.children[1]
  );
}

// handling click event for editing
function handler(e) {
  if (
    ![
      'devnagri_owner_button Devnagri-tooltip-toggle',
      'devnagri_owner_send_button',
      'devnagri_owner_cancel_button',
      'devnagri_open_button',
      'devnagri_container',
      'devnagri_optionElement',
      'devnagri_seo_cancel',
      'devnagri_seo_submit',
      'devnagri_seo',
      'devnagri_logo',
      'devnagri_written_lang',
      'devnagri_upArrow',
      'devnagri_outermostdivclass'
    ].includes(e.target.className)
  ) {
    e.stopPropagation();
    e.preventDefault();
    e.stopImmediatePropagation();
  }
}

// ui for Devnagri website owner button
async function WebOwnerEnableButton() {
  const div = document.createElement('div');
  div.setAttribute('style', 'float:left');
  const button = document.createElement('div');
  button.classList.add('devnagri_owner_button', 'Devnagri-tooltip-toggle');
  button.id = 'devnagri_optionElementt';
  button.setAttribute('style', 'background-color:white');
  button.setAttribute('data-tooltip', 'Edit');
  button.innerHTML = edit_button_svg_content;
  button.onclick = () => {
    closeDropdown();
    AddDataAttributeDataChangeListioner();
    button.remove();
    contentEditt(document.body);

    webOwner();
    document.addEventListener('click', handler, true);
  };
  div.appendChild(button);
  global_variables.initialdiv.appendChild(div);
  const verticalLineDiv = document.createElement('div');
  verticalLineDiv.setAttribute('style', 'float:left');
  const vl = document.createElement('div');
  vl.setAttribute('id', 'devnagri_vl_logo');
  vl.classList.add('devnagri_vl');
  verticalLineDiv.appendChild(vl);
  global_variables.initialdiv.appendChild(verticalLineDiv);
}

// make avtive lang with blue color
function activeLanguage(selected_lang_code) {
  if (
    global_variables.should_render_ui &&
    global_variables.should_render_ui.toLowerCase() === 'custom'
  ) {
    return true;
  }
  if (
    (global_variables.status_api_global_data.enableSubdomainRedirect || isProxy()) &&
    !global_variables.web_owner
  ) {
    const x = document.querySelectorAll('#devnagri_li');

    for (let i = 0; i < x.length; i++) {
      if (x[i].getAttribute('devnagri_lang_code_attribute') == selected_lang_code) {
        if (x[i].firstChild) {
          global_variables.defaultLanguage.innerText = x[i].firstChild.innerText;
          const dropdown_color = global_variables.status_api_global_data.dropdown_color
            ? global_variables.status_api_global_data.dropdown_color
            : 'blue';
          x[i].firstChild.setAttribute('style', `color:${dropdown_color}`);
        }
      } else if (x[i].firstChild) {
        x[i].firstChild.removeAttribute('style');
      }
    }
  } else {
    const x = document.querySelectorAll('#devnagri_li');
    for (let i = 0; i < x.length; i++) {
      if (x[i].getAttribute('devnagri_lang_code_attribute') == selected_lang_code) {
        global_variables.defaultLanguage.innerText = x[i].innerText;
        const dropdown_color = global_variables.status_api_global_data.dropdown_color
          ? global_variables.status_api_global_data.dropdown_color
          : 'blue';
        x[i].setAttribute('style', `color:${dropdown_color}`);
      } else {
        x[i].removeAttribute('style');
      }
    }
  }
}

// when lang is selected from dropdown then this function will work
async function onLangSelection(selected_lang_code) {
  if (isLiveJs()) {
    const old_status = global_variables.subscription_status_active;

    global_variables.subscription_status_active = isUrlLangStatusActive(selected_lang_code);
    if (!old_status && global_variables.subscription_status_active) {
      global_variables.subdomainObjectStore = selected_lang_code;
      startTraversingNow();
      await checkIfTraversingCompleted();
    }

    // only in case of LiveJs() - to show last selected language in dropdown, on MPA(multipage web application), while changing pages/links
    localStorage.setItem('devnagri_last_selected_lang_code', selected_lang_code);
  }

  if (global_variables.edit_mode_enabled) {
    const element = document.querySelector('.devnagri_unsavedChanges');
    if (element) {
      element.remove();
    }
    remove_contentEditt(document.body);
    if (selected_lang_code !== global_variables.source_lang_code) {
      onLangSelection(global_variables.source_lang_code);
    }
  }

  // - TODO: uncomment this
  // for showing and hiding pencil icon
  // englishSelectPencile(selected_lang_code);
  RemoveDataAttributeDataChangeListioner();
  const element = document.querySelector('.devnagri_unsavedChanges');
  if (element) {
    element.remove();
  }
  // close the UI dropdown
  closeDropdown();
  // set the active lanauge CSS on the dropdown option link
  activeLanguage(selected_lang_code);

  // set text direction rtl for urdu
  const rtlLangs = ['ur', 'ar'];
  if (rtlLangs.includes(selected_lang_code)) {
    document.body.setAttribute('dir', 'rtl');
  } else {
    document.body.setAttribute('dir', 'ltr');
  }

  let width;
  const dotaSelectContainer = document.querySelector('.devnagri_open_button');
  if (dotaSelectContainer) {
    width = dotaSelectContainer.offsetWidth;
  }

  const dropdownContainer = document.getElementById('devnagri_dropdown');
  if (dropdownContainer) {
    dropdownContainer.setAttribute('style', `width:${width}px;`);
  }

  if (selected_lang_code === localStorage.getItem('devnagri_source_lang_code')) {
    global_variables.selected_lang_code = selected_lang_code;
    global_variables.default_lang_code = selected_lang_code;
    // localStorage.setItem("devnagri_selected_lang_code", selected_lang_code);
    // localStorage.setItem("devnagri_default_lang_code", selected_lang_code);
    translate(selected_lang_code);
  } else if (
    global_variables.default_lang_code === selected_lang_code
    // localStorage.getItem("devnagri_default_lang_code") == selected_lang_code
  ) {
    global_variables.selected_lang_code = selected_lang_code;
    global_variables.default_lang_code = selected_lang_code;

    GetTranslationsApi().then(() => {
      translate(selected_lang_code);
      AddDataAttributeDataChangeListioner();
    });
  } else if (selected_lang_code !== global_variables.default_lang_code) {
    global_variables.default_lang_code = selected_lang_code;
    global_variables.selected_lang_code = selected_lang_code;
    global_variables.subdomainObjectStore = selected_lang_code;
    GetTranslationsApi().then(() => {
      translate(selected_lang_code);
      AddDataAttributeDataChangeListioner();
    });
  }
}

function compareArrays(a, b) {
  return a.length === b.length && a.every((element, index) => element === b[index]);
}

async function WebOwnerEnableButtonInsert() {
  const div = document.createElement('div');
  div.setAttribute('style', 'float:left');
  const button = document.createElement('div');
  button.classList.add('devnagri_owner_button', 'Devnagri-tooltip-toggle');
  button.id = 'devnagri_optionElementt';
  button.setAttribute('style', 'background-color:white');
  button.setAttribute('data-tooltip', 'Edit');
  button.onclick = () => {
    closeDropdown();
    AddDataAttributeDataChangeListioner();
    button.remove();
    document.getElementById('devnagri_vl_logo').remove();
    contentEditt(document.body);
    webOwner();
    document.addEventListener('click', handler, true);
  };
  div.appendChild(button);
  const verticalLineDiv = document.createElement('div');
  verticalLineDiv.setAttribute('style', 'float:left');
  const vl = document.createElement('div');
  vl.setAttribute('id', 'devnagri_vl_logo');
  vl.classList.add('devnagri_vl');
  verticalLineDiv.appendChild(vl);
  const logoParent = document.querySelector('.devnagri_logo').parentElement;
  global_variables.initialdiv.insertBefore(div, logoParent);
  global_variables.initialdiv.insertBefore(verticalLineDiv, logoParent);
}

// this function will be used for hiding pencil icon
function englishSelectHidePencile() {
  if (
    document.querySelector('.devnagri_owner_button') &&
    document.querySelector('.devnagri_owner_button').style.display == 'none'
  ) {
    document.querySelector('.devnagri_owner_button').style.display = 'block';
    document.querySelector('.devnagri_vl').style.display = 'block';
  } else if (!document.querySelector('.devnagri_owner_button')) {
    if (
      document.getElementById('devnagri_seo_button_id') &&
      document.getElementById('devnagri_seo_button_id').style.display == 'block'
    ) {
      document.getElementById('devnagri_seo_button_id').style.display = 'none';
      document.querySelector('.devnagri_owner_send_button').style.display = 'none';
      document.querySelector('.devnagri_owner_cancel_button').style.display = 'none';

      remove_contentEditt(document.body);
      WebOwnerEnableButtonInsert();
      document.querySelector('#devnagri_vl_seo').style.display = 'none';
      document.querySelector('#devnagri_vl_logo').style.display = 'none';
    } else {
      document.getElementById('devnagri_seo_button_id').style.display = 'none';
      document.querySelector('.devnagri_owner_send_button').style.display = 'none';
      document.querySelector('.devnagri_owner_cancel_button').style.display = 'none';

      remove_contentEditt(document.body);
      WebOwnerEnableButtonInsert();
      document.getElementById('devnagri_vl_seo').style.visibility = 'hidden';
      document.getElementById('devnagri_vl_seo').parentElement.style.width = '0';

      document.getElementById('devnagri_vl_logo').style.visibility = 'hidden';
      document.getElementById('devnagri_vl_logo').parentElement.style.width = '0';
    }

    const verticalLines = document.getElementsByClassName('devnagri_vl');
    for (let i = 0; i < verticalLines.length; i++) {
      verticalLines[i].style.display = 'block';
    }
  }
}

function englishSelectPencile(selected_lang_code) {
  if (global_variables.web_owner && selected_lang_code.toLowerCase() == 'en') {
    // for showing pencil icon
    englishSelectShowPencile();
  } else if (
    (document.getElementById('devnagri_seo_button_id') &&
      document.getElementById('devnagri_seo_button_id').style.display == 'none') ||
    global_variables.web_owner
  ) {
    // for hiding pencil icon
    englishSelectHidePencile();
  }
}

// this function will be used for showing pencil icon
function englishSelectShowPencile() {
  if (!document.querySelector('.devnagri_owner_button')) {
    remove_contentEditt(document.body);
  }
  if (
    document.querySelector('.devnagri_owner_button') &&
    document.querySelector('.devnagri_owner_button').style.display != 'none'
  ) {
    document.querySelector('.devnagri_owner_button').style.display = 'none';
    contentEdit();
    document.querySelector('.devnagri_vl').style.display = 'none';
  }
  if (document.getElementById('devnagri_seo_button_id')) {
    document.getElementById('devnagri_seo_button_id').style.display = 'none';
    document.querySelector('.devnagri_owner_send_button').style.display = 'none';
    document.querySelector('.devnagri_owner_cancel_button').style.display = 'none';
    const verticalLines = document.getElementsByClassName('devnagri_vl');
    for (let i = 0; i < verticalLines.length; i++) {
      verticalLines[i].style.display = 'none';
    }
  }
}

async function updatePageCache() {
  if (global_variables.is_browser_cached) return;
  // const req = new XMLHttpRequest();
  // req.open('HEAD', document.location, false);
  // req.send(null);
  // const html = document.documentElement.outerHTML;
  // const headers = req.getAllResponseHeaders();
  // const status = req.status;
  const pageRes = await axios.get(document.location.href);
  const html = document.documentElement.outerHTML;
  const headers = pageRes.headers;
  const status = pageRes.status;

  const pageCacheUrl = `${global_variables.api_baseurl}/add-cache`;

  await axios.post(
    pageCacheUrl,
    {
      englishUrl: global_variables.document_url_full,
      url: document.location.href,
      domain: global_variables.config.requested_domain,
      englishDomain: global_variables.domain,
      data: html,
      headers: headers,
      status: status
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  global_variables.is_browser_cached = true;
}

module.exports = {
  GetTranslationsApi,
  sentenceUpdateApi,
  callApiRequest,
  authenticate,
  loginApi,
  subscriptionStatusApi,
  checkAuthenticationToken,
  getObjectUrlsApi,
  pageViewsApi,
  // dom
  checkNodeType,
  traverseNodes,
  startTraversingNow,
  addNodeToArray,
  checkIfTraversingCompleted,
  updateNodesWithTranslatedText,
  translate,
  setTranslatedTextToNode,
  setTranslatedTextToElement,
  domNodeInsertedHandler,
  translateAllFramesOnPage,
  DataAttributeDataChangeListioner,
  RemoveDataAttributeDataChangeListioner,
  AddDataAttributeDataChangeListioner,
  contentEditt,
  uiErrorContextEditor,
  showErrorMessage,
  contextEditorPatchApi,
  seoEditedText,
  seo_ui,
  checkingContentEditable,
  saveChangesUI,
  webOwnerSend,
  webOwnerCancel,
  webOwner,
  handler,
  WebOwnerEnableButton,
  isNodeASentence,
  updatePageCache,
  onLangSelection
};
